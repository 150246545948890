import { v4 as uuidv4 } from 'uuid'
import { invitation } from '../../../actions'
import { success } from '../../actions'
import { toastStatus } from '../../helpers'
import { availableIcon } from '../../helpers/toastStatus'

export default {
  [invitation.send.success.type]: ({ next, action }) => {
    next(
      success({
        id: uuidv4(),
        icon: availableIcon.SUCCESS,
        status: toastStatus.SUCCESS,
        title: 'Votre invitation a bien été envoyée !',
        description: '',
      })
    )
    next(action)
  },
  [invitation.deleteInvitation.success.type]: ({ next, action }) => {
    next(
      success({
        id: uuidv4(),
        icon: availableIcon.SUCCESS,
        status: toastStatus.SUCCESS,
        title: 'Votre invitation a bien été supprimée !',
        description: '',
      })
    )
    next(action)
  },
  [invitation.accept.success.type]: ({ next, action }) => {
    next(
      success({
        id: uuidv4(),
        icon: availableIcon.SUCCESS,
        status: toastStatus.SUCCESS,
        title: 'Votre nouveau contact à été ajouté à vos proches !',
        description: '',
      })
    )
    next(action)
  },
}
