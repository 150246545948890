import api from '../../services/api'
import { sagas } from '../../utils'
import { deleteInvitation as actions } from '../actions'

export default () =>
  sagas.createDefaultSaga({
    apiCall: api.invitation.deleteInvitation,
    actions,
    payloadKey: 'deleteInvitation',
  })
