import styled from 'styled-components'
import { Box } from '@chakra-ui/react'

export const AccountSubsriptionWrapper = styled(Box)`
  border-radius: 16px;
  background: white;
  text-align: center;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  .illustration__card {
    width: 340px;
    position: relative;
    margin: 0 auto;
    .user {
      position: absolute;
      font-size: 12px;
      left: 9.76%;
      top: 72.02%;
    }
    .date {
      position: absolute;
      right: 30.52%;
      top: 72.02%;
      font-size: 12px;
    }
  }
`
