import { createSelector } from 'reselect'
import _ from 'lodash'

export const mergeErrorsSelector = (selectors) =>
  createSelector(...selectors, (...errors) =>
    errors.filter((error) => !_.isNil(error))
  )

export const mergeLoadingSelector = (selectors) =>
  createSelector(...selectors, (...loadings) => loadings.includes(true))
