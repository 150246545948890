import { takeEvery } from 'redux-saga/effects'
import * as actions from '../actions'
import authenticate from './authenticate'
import setOnBoardingStep from './onBoardingStep'
import initiateVerification from './initiateVerification'
import getUserTokenSaga from './getUserToken'
import paySaga from './pay'

export default () =>
  function* authSagas() {
    yield takeEvery(actions.checkPhone.process.type, authenticate())
    yield takeEvery(actions.getUserToken.process.type, getUserTokenSaga())
    yield takeEvery(actions.onBoardingStep.process.type, setOnBoardingStep())
    yield takeEvery(
      actions.initiateVerification.process.type,
      initiateVerification()
    )
    yield takeEvery(actions.pay.process.type, paySaga())
  }
