export default class HTTPError extends Error {
  constructor(status, data) {
    super()
    this.data = data
    this.status = status
    this.isHTTP = true
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }
}
