import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { reset as resetAction } from '../actions'
import send from './send'
import accept from './accept'
import deleteInvitation from './deleteInvitation'

import { reducers as reducerHelpers } from '../../utils'

const INITIAL_STATE = _.merge(
  {},
  send.initialState,
  accept.initialState,
  deleteInvitation.initialState
)

const actionsMap = _.merge(
  {},
  send.actionsMap,
  accept.actionsMap,
  deleteInvitation.actionsMap,
  {
    [resetAction]: (state, action) =>
      reducerHelpers.resetState(state, INITIAL_STATE, action.payload.keys),
  }
)

export default createReducer(INITIAL_STATE, actionsMap)
