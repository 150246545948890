import { combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { reducer as sessionReducer } from './session'
import { reducer as UXSiteReducer } from './UXSite'
import { reducer as contactReducer } from './contact'
import { reducer as userReducer } from './user'
import { reducer as paymentReducer } from './payment'
import { reducer as invitationReducer } from './invitation'
import { reducer as weatherReducer } from './weather'
import { reducer as mediaReducer } from './media'
import { reducer as toastReducer } from './toast'
import { logout } from './session/actions'
import { errorReducer } from './error/reducer'

const appReducer = combineReducers({
  session: sessionReducer,
  UXSite: UXSiteReducer,
  contact: contactReducer,
  user: userReducer,
  payment: paymentReducer,
  invitation: invitationReducer,
  weather: weatherReducer,
  media: mediaReducer,
  toast: toastReducer,
  errors: errorReducer,
})

export const reducer = (state, action) => {
  if (action.type === logout.process.type) {
    storage.removeItem('persist:INDEX_STORAGE')
    state = undefined
  }
  return appReducer(state, action)
}
