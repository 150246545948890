import { createAction as createActionRedux } from '@reduxjs/toolkit'
import { serializeError } from 'serialize-error'
import _ from 'lodash'

const prefix = process.env.REACT_APP_NAME
  ? `${process.env.REACT_APP_NAME}/`
  : ''

export default (type) =>
  createActionRedux(`${prefix}${type}`, (payload) => {
    const action = {}
    if (!payload) {
      return action
    }
    if (payload.error) {
      action.error = serializeError(payload.error)
      action.payload = _.omit(payload, 'error')
    } else {
      action.payload = payload
    }
    return action
  })
