import React from 'react'
import { Image, Box } from '@chakra-ui/react'

const Avatar = (props) => {
  const { src, firstName } = props

  if (src) {
    return <Image borderRadius="full" boxSize="50px" bg="white" {...props} />
  }
  return (
    <Box
      borderRadius="full"
      boxSize={77}
      bg="primary.400"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box as="h1" textStyle="h1" color="white" fontWeight={700} mt={1}>
        {firstName.slice(0, 1).toUpperCase()}
      </Box>
    </Box>
  )
}

export default Avatar
