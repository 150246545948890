import { selectors } from '../../utils'

const { mergeLoadingSelector } = selectors

export const checkPhoneLoadingSelector = (state) =>
  state.session.loading.checkPhone
export const getUserTokenLoadingSelector = (state) =>
  state.session.loading.getUserToken

export const loadingSelector = mergeLoadingSelector([
  checkPhoneLoadingSelector,
  getUserTokenLoadingSelector,
])
