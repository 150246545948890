import React from 'react'
import { Box, Button, Stack } from '@chakra-ui/react'
import { FormCard } from '../../../../components/cards'
import { BackButton } from '../../../../components'

const CircleLi = () => (
  <Box
    display="block"
    height="10px"
    width="10px"
    borderRadius="10px"
    bg="primary.400"
    mr={4}
    mt={1.5}
  />
)

const HorizontalBar = () => (
  <Box
    display="block"
    height="24px"
    width="2px"
    borderRadius="10px"
    bg="lightBrown.500"
    ml={1}
  />
)

const Index = ({ next, prev }) => (
  <>
    {' '}
    <BackButton callback={() => prev()} />
    <FormCard>
      <Stack spacing={5}>
        <Box as="h2" textStyle="h2">
          Mon abonnement inclut
        </Box>
        <Box>
          <Box display="flex">
            <CircleLi />
            <Box as="p" textStyle="p" textAlign="left">
              Tous les services Index
            </Box>
          </Box>
          <HorizontalBar />
          <Box display="flex">
            <CircleLi />
            <Box as="p" textStyle="p" textAlign="left">
              Accès illimité sur tous vos appareils
            </Box>
          </Box>
          <HorizontalBar />
          <Box display="flex">
            <CircleLi />
            <Box as="p" textStyle="p" textAlign="left">
              Sans engagement
            </Box>
          </Box>
          <HorizontalBar />
          <Box display="flex">
            <CircleLi />
            <Box as="p" textStyle="p" textAlign="left">
              Abonnement résiliable en ligne à tout <br />
              moment
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            as="p"
            textStyle="p"
            color="primary.400"
            textTransform="uppercase"
          >
            Pour seulement
          </Box>
          <Box as="h2" textStyle="h2" fontSize={30} fontWeight={700}>
            12,95€/
            <Box as="span" textStyle="span" fontSize={16}>
              mois
            </Box>
          </Box>
        </Box>
        <Button colorScheme="primary" onClick={() => next()}>
          Je m'abonne
        </Button>
      </Stack>
    </FormCard>
  </>
)

export default Index
