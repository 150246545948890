import { fork, all } from 'redux-saga/effects'
import { watchErrors } from './error/sagas'
import { sagas as authSagas } from './session'
import { sagas as UXSiteSagas } from './UXSite'
import { sagas as contactSagas } from './contact'
import { sagas as userSagas } from './user'
import { sagas as paymentSagas } from './payment'
import { sagas as invitationSagas } from './invitation'
import { sagas as weatherSagas } from './weather'
import { sagas as mediaSagas } from './media'

export function* rootSaga() {
  yield all([
    yield fork(authSagas()),
    fork(UXSiteSagas()),
    fork(contactSagas()),
    fork(userSagas()),
    fork(paymentSagas()),
    fork(invitationSagas()),
    fork(weatherSagas()),
    fork(mediaSagas()),
    yield fork(watchErrors),
  ])
}
