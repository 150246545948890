import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectors } from '../../store/selectors'
import { paths } from '../paths'
import { onBoardingStepper } from '../../helpers/onBoardingStepper'

const Index = () => {
  const token = useSelector(selectors.session.tokenSelector)
  const onBoarding = useSelector(selectors.session.onBoardingSelector)
  const user = useSelector(selectors.user.userSelector)

  if (
    user?.status === 'active' &&
    token &&
    onBoarding.onBoardingStep === onBoardingStepper.connected
  )
    return <Navigate replace to={paths.WALL} />
  return <Outlet />
}

export default Index
