import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { reset as resetAction } from '../actions'

import update from './update'
import me from './me'
import profilePicture from './profilePicture'
import deleteAccount from './deleteAccount'

import { reducers as reducerHelpers } from '../../utils'
import { getUserToken } from '../../session/actions'

const INITIAL_STATE = _.merge(
  {},
  me.initialState,
  update.initialState,
  profilePicture.initialState,
  deleteAccount.initialState
)

const actionsMap = _.merge(
  {
    [getUserToken.success]: (state, action) => {
      const newState = _.cloneDeep(state)
      newState.user = action.payload.user
      return newState
    },
  },
  me.actionsMap,
  update.actionsMap,
  profilePicture.actionsMap,
  deleteAccount.actionsMap,
  {
    [resetAction]: (state, action) =>
      reducerHelpers.resetState(state, INITIAL_STATE, action.payload.keys),
  }
)

export default createReducer(INITIAL_STATE, actionsMap)
