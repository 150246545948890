import React, { useState } from 'react'
import {
  Box,
  Button,
  Stack,
  RadioGroup,
  HStack,
  Checkbox,
  Link,
} from '@chakra-ui/react'
import { Field, Form } from 'react-final-form'
import { InputRadio, InputSelect, InputText } from '../../../../components/form'
import {
  composeValidators,
  postalCode,
  required,
} from '../../../../helpers/fieldValidator'
import {
  normalizeCreditCardNumber,
  formatCrediCardNumber,
} from '../../../../helpers/formatCreditCardNumber'
import { FormCard } from '../../../../components/cards'
import { BackButton } from '../../../../components'
import { FaCreditCard } from '../../../../components/Svg'
import { legalsLink } from '../../../../helpers/legalsLinks'

const Index = ({ callback, prev }) => {
  const [CGUAccepted, setCGUAccepted] = useState(false)
  const [CGVAccepted, setCGVAccepted] = useState(false)

  const months = [
    { name: 'janvier', value: '01' },
    { name: 'février', value: '02' },
    { name: 'mars', value: '03' },
    { name: 'avril', value: '04' },
    { name: 'mai', value: '05' },
    { name: 'juin', value: '06' },
    { name: 'juillet', value: '07' },
    { name: 'août', value: '08' },
    { name: 'septembre', value: '09' },
    { name: 'octobre', value: '10' },
    { name: 'novembre', value: '11' },
    { name: 'décembre', value: '12' },
  ]

  const getYears = () => {
    const years = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 24; i++) {
      let newYear = new Date().getFullYear()
      newYear += i
      years.push({ name: newYear, value: newYear })
    }
    return years
  }

  const formatValues = ({
    firstname,
    lastname,
    gender,
    city,
    // eslint-disable-next-line camelcase
    postal_code,
    // eslint-disable-next-line camelcase
    street_name,
    number,
    expMonth,
    expYear,
    cvc,
  }) => ({
    firstname,
    lastname,
    gender,
    address: {
      city,
      // eslint-disable-next-line camelcase
      postal_code,
      // eslint-disable-next-line camelcase
      street_name,
    },
    // TODO: waiting for stripe
    // card: {
    //   number: formatCrediCardNumber(number),
    //   expMonth,
    //   expYear,
    //   cvc,
    // },
  })

  return (
    <>
      <BackButton callback={() => prev()} />
      <FormCard>
        <Box as="h2" textStyle="h2" mb={4}>
          Mes infos de paiement
        </Box>

        <Stack spacing={4}>
          <Form
            onSubmit={(values) => callback(formatValues(values))}
            initialValues={{
              gender: 'male',
              firstname: 'John',
              lastname: 'Coffey',
              street_name: 'rue du dream',
              postal_code: '51100',
              city: 'Reims',
            }}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={4}>
                  <Box textAlign="left">
                    <RadioGroup value={values.gender}>
                      <Stack spacing={5} direction="row">
                        <Field
                          name="gender"
                          component={InputRadio}
                          validate={composeValidators(required)}
                          type="radio"
                          value="male"
                          label="Mr"
                          id="male"
                          colorScheme="primary"
                        />
                        <Field
                          name="gender"
                          component={InputRadio}
                          validate={composeValidators(required)}
                          type="radio"
                          value="female"
                          label="Mme"
                          id="female"
                          colorScheme="primary"
                        />
                      </Stack>
                    </RadioGroup>
                  </Box>
                  <Field
                    name="firstname"
                    component={InputText}
                    label="Prénom"
                    validate={composeValidators(required)}
                  />
                  <Field
                    name="lastname"
                    component={InputText}
                    label="Nom"
                    validate={composeValidators(required)}
                  />
                  <Box
                    as="h3"
                    textStyle="h3"
                    fontWeight={400}
                    color="primary.400"
                    textAlign="left"
                    mt={3}
                    mb={3}
                  >
                    Adresse
                  </Box>
                  <Field
                    name="street_name"
                    component={InputText}
                    validate={composeValidators(required)}
                    label="Nom et numéro de rue"
                  />
                  <Field
                    name="postal_code"
                    type="number"
                    component={InputText}
                    validate={composeValidators(required, postalCode)}
                    label="Code postal"
                  />
                  <Field
                    name="city"
                    component={InputText}
                    validate={composeValidators(required)}
                    label="Ville"
                  />
                  {/* <Box */}
                  {/*  as="h3" */}
                  {/*  textStyle="h3" */}
                  {/*  fontWeight={400} */}
                  {/*  color="primary.400" */}
                  {/*  textAlign="left" */}
                  {/* > */}
                  {/*  Mes infos de paiement */}
                  {/* </Box> */}
                  {/* <Field */}
                  {/*  name="number" */}
                  {/*  type="text" */}
                  {/*  component={InputText} */}
                  {/*  validate={composeValidators(required)} */}
                  {/*  parse={normalizeCreditCardNumber} */}
                  {/*  placeholder="Numéro de carte" */}
                  {/*  LeftIcon={FaCreditCard} */}
                  {/* /> */}
                  {/* <HStack spacing={3}> */}
                  {/*  <Field */}
                  {/*    name="expMonth" */}
                  {/*    component={InputSelect} */}
                  {/*    validate={composeValidators(required)} */}
                  {/*    placeholder="Mois" */}
                  {/*    labelValueNull="Mois" */}
                  {/*    options={months} */}
                  {/*  /> */}
                  {/*  <Field */}
                  {/*    name="expYear" */}
                  {/*    component={InputSelect} */}
                  {/*    validate={composeValidators(required)} */}
                  {/*    placeholder="Année" */}
                  {/*    labelValueNull="Année" */}
                  {/*    options={getYears()} */}
                  {/*  /> */}
                  {/* </HStack> */}

                  {/* <Field */}
                  {/*  name="cvc" */}
                  {/*  component={InputText} */}
                  {/*  validate={composeValidators(required)} */}
                  {/*  label="Cryptogramme (CVV)" */}
                  {/* /> */}
                  <Checkbox
                    size="lg"
                    colorScheme="white"
                    iconColor="primary.400"
                    borderColor="#F4F0E3"
                    onChange={() => setCGUAccepted(!CGUAccepted)}
                  >
                    <Link
                      className="link"
                      href={legalsLink.CGU}
                      target="_blank"
                    >
                      CGU
                    </Link>
                  </Checkbox>
                  {/* <Checkbox */}
                  {/*  size="lg" */}
                  {/*  colorScheme="white" */}
                  {/*  iconColor="primary.400" */}
                  {/*  borderColor="#F4F0E3" */}
                  {/*  onChange={() => setCGVAccepted(!CGVAccepted)} */}
                  {/* > */}
                  {/*  J’accepte les{' '} */}
                  {/*  <Link */}
                  {/*    className="link" */}
                  {/*    href={legalsLink.CGV} */}
                  {/*    target="_blank" */}
                  {/*  > */}
                  {/*    CGV */}
                  {/*  </Link> */}
                  {/* </Checkbox> */}

                  <Button
                    type="submit"
                    // isDisabled={!CGVAccepted && !CGUAccepted}
                    isDisabled={!CGUAccepted}
                  >
                    Valider
                  </Button>
                </Stack>
              </form>
            )}
          />
        </Stack>
      </FormCard>
    </>
  )
}

export default Index
