import * as React from 'react'

const SvgFaRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 14"
    style={{
      enableBackground: 'new 0 0 12 14',
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    role="img"
    {...props}
  >
    <path
      d="M6.6.5C6.3.2 5.8.2 5.5.4c-.3.3-.3.8-.1 1.1l4 4.8H1c-.4-.1-.8.3-.8.7s.4.7.8.7h8.4l-4 4.8c-.3.3-.2.8.1 1.1.3.3.8.2 1.1-.1l5-6c.1-.1.2-.3.2-.5s-.1-.3-.2-.5l-5-6z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: 'currentcolor',
      }}
    />
  </svg>
)
export default SvgFaRight
