import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Box, Button } from '@chakra-ui/react'
import { useReactMediaRecorder } from 'react-media-recorder'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { CallWrapper } from '../Call'
import { FaMic, FaStop } from '../../../../components/Svg'
import AudioRecorded from '../../audio-recorded-illustration.svg'
import { AudioPlayer } from './audioPlayer'
import { actions } from '../../../../store'
import { selectors } from '../../../../store/selectors'
import Loader from '../../../../components/Loader'

const AnimationRecButton = styled(Box)`
  animation: up 1s alternate infinite ease;

  @keyframes up {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(0.9);
    }
  }
`

const RecordView = ({ recipientUuid }) => {
  const [audio, setAudio] = useState(undefined)

  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      video: false,
      mediaRecorderOptions: { mimeType: 'audio/webm' },
    })

  useEffect(() => {
    if (mediaBlobUrl) {
      setAudio(mediaBlobUrl)
    }
    return () => {
      setAudio(undefined)
    }
  }, [mediaBlobUrl])

  const downloadBlob = async (audioBlob) => {
    const blobbed = await fetch(audioBlob)
      .then((r) => r.blob())
      .catch((e) => console.log(e))

    return blobbed
  }

  const formatAudio = async () => {
    const donwloadedAudioBlob = await downloadBlob(audio)
    const audioFile = new File([donwloadedAudioBlob], 'audiofile.webm', {
      type: 'audio/webm',
    })

    const formData = {
      file: audioFile,
      recipientUuid,
    }

    return formData
  }

  const sentAudio = useSelector(selectors.media.sendAudioSelector)
  const sendAudioLoader = useSelector(selectors.media.sendAudioLoaderSelector)

  const dispatch = useDispatch()
  const sendAudio = async () => {
    dispatch(actions.media.sendAudio.process(await formatAudio(audio)))
  }

  useEffect(() => {
    if (sentAudio) {
      setAudio(null)
      dispatch(actions.media.reset({ key: ['sendAudio'] }))
    }
  }, [dispatch, sentAudio])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {sendAudioLoader ? (
        <Loader />
      ) : (
        <>
          <Box
            p={5}
            bg="lightblue.50"
            backgroundImage={(audio || status === 'recording') && AudioRecorded}
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundSize="contain"
            minW="400px"
            minH="122px"
            borderRadius={8}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className="audio__wrapper"
          >
            {audio && status !== 'idle' ? (
              <AudioPlayer audio={audio} />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {status === 'recording' ? (
                  <Box
                    display="flex"
                    flex={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      h="50px"
                      w="50px"
                      borderRadius="full"
                      background="#6891FB20"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color="primary.400"
                      fontSize={40}
                    >
                      <FaMic />
                    </Box>
                    <AnimationRecButton
                      h="50px"
                      w="50px"
                      borderRadius="full"
                      background="red.400"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      color="white"
                      fontSize={20}
                      onClick={stopRecording}
                      cursor="pointer"
                    >
                      <FaStop />
                    </AnimationRecButton>
                  </Box>
                ) : (
                  <Box
                    h="80px"
                    w="80px"
                    borderRadius="full"
                    background="lightblue.400"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    fontSize={50}
                    onClick={startRecording}
                    cursor="pointer"
                  >
                    <FaMic />
                  </Box>
                )}
              </>
            )}
          </Box>
          {audio && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={4}
            >
              <Button
                variant="unstyled"
                color="lightblue.400"
                onClick={() => setAudio(undefined)}
              >
                Annuler
              </Button>
              <Button colorScheme="lightblue" onClick={() => sendAudio()}>
                Envoyer
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  )
}

const Index = () => {
  const currentContact = useOutletContext()
  return (
    <CallWrapper>
      <Box as="h2" textStyle="h2">
        Envoyer un message vocal à {currentContact.firstName}
      </Box>
      <Box as="p" textStyle="p" color="primary.400">
        Cliquez sur le micro pour enregistrer votre message
      </Box>

      <RecordView recipientUuid={currentContact.uuid} />
    </CallWrapper>
  )
}

export default Index
