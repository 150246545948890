export const formatPhoneNumber = (phoneNumber) =>
  `%2B33${phoneNumber.replace(/[ ]/g, '').slice(1)}`

export const formatPhoneNumberJson = (phoneNumber) =>
  `+33${phoneNumber.replace(/[ ]/g, '').slice(1)}`

export const normalizePhone = (value) => {
  if (!value) return value
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 2) return onlyNums
  if (onlyNums.length <= 4)
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 4)}`
  if (onlyNums.length <= 6)
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 4)} ${onlyNums.slice(
      4,
      6
    )}`
  if (onlyNums.length <= 8)
    return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 4)} ${onlyNums.slice(
      4,
      6
    )} ${onlyNums.slice(6, 8)}`
  return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 4)} ${onlyNums.slice(
    4,
    6
  )} ${onlyNums.slice(6, 8)} ${onlyNums.slice(8, 10)}`
}
