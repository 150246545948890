import React from 'react'
import { Button, Box, Stack } from '@chakra-ui/react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLottie } from 'lottie-react'
import { paths } from '../../router/paths'
import { selectors } from '../../store/selectors'
import { actions } from '../../store'
import LottieTest from '../LottieTest.json'
import { LandingPageWrapper, LottieWrapper } from '../../styles/landingPage'
import backgroundFamily from '../../styles/landingPage/lotties/backgroundFamily.json'
import { LogoIndex } from '../../components/Logo'

const Index = () => {
  const dispatch = useDispatch()
  const landingPageSeen = useSelector(selectors.UXSite.landingPageSeenSelector)
  const setLandingPageSeen = () =>
    dispatch(actions.UXSite.landingPageSeen.process())

  const family = {
    animationData: LottieTest,
    loop: true,
  }

  const background = {
    animationData: backgroundFamily,
    loop: true,
  }

  const { View: Background } = useLottie(background)
  const { View: Family } = useLottie(family)

  if (landingPageSeen) return <Navigate replace to={paths.ON_BOARDING} />

  return (
    <LandingPageWrapper bg="secondary.400">
      <Box className="onboarding__baseline">
        <Stack spacing={3}>
          <LogoIndex />
          <Box as="h1" textStyle="h1">
            L’application qui <br />
            reconnecte les générations
          </Box>
        </Stack>
      </Box>
      <Box className="slider__landing__page">
        <LottieWrapper>
          <div className="background">
            <div>{Background}</div>
          </div>
          <div className="foreground">
            <div>{Family}</div>
          </div>
        </LottieWrapper>
        <Box className="baseline__slider">
          <Box as="h2" textStyle="h2" mb={4}>
            Index.tv, l’application <br />
            qui reconnecte les générations
          </Box>
          <Button onClick={() => setLandingPageSeen()} size="lg">
            Suivant
          </Button>
        </Box>
      </Box>
      <Box className="circle" />
    </LandingPageWrapper>
  )
}

export default Index
