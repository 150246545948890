import { takeEvery } from 'redux-saga/effects'
import * as actions from '../actions'
import send from './send'
import accept from './accept'
import deleteInvitation from './deleteInvitation'

export default () =>
  function* contactSagas() {
    yield takeEvery(actions.send.process.type, send())
    yield takeEvery(actions.accept.process.type, accept())
    yield takeEvery(actions.deleteInvitation.process.type, deleteInvitation())
  }
