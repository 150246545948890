import * as React from 'react'

const SvgFaBack = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.424 13.48a.75.75 0 1 0 1.152-.96L2.601 7.75H11a.75.75 0 0 0 0-1.5H2.601l3.975-4.77A.75.75 0 0 0 5.424.52L.427 6.516a.747.747 0 0 0 0 .968l4.997 5.996Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaBack
