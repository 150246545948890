import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  HStack,
  Image,
  FormLabel,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { FormCard } from '../../../components/cards'
import { InputRadio, InputSelect, InputText } from '../../../components/form'
import {
  composeValidators,
  required,
  postalCode,
  email,
} from '../../../helpers/fieldValidator'
import { selectors } from '../../../store/selectors'
import { actions } from '../../../store'
import Loader from '../../../components/Loader'
import { FaBack, FaCross, FaLock, FaRight } from '../../../components/Svg'
import { theme } from '../../../styles/themeConfig'

const AnimateArrow = styled(Box)`
  svg {
    font-size: 30px;
  }
  .arrow__left {
    animation: down 1s alternate infinite ease-in;
  }

  .arrow__right {
    animation: up 1s alternate infinite ease-in;
  }

  @keyframes up {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(0.9);
    }
  }
  @keyframes down {
    0% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1.1);
    }
  }
`

const Index = () => {
  const user = useSelector(selectors.user.userSelector)
  const userLoader = useSelector(selectors.user.updateUserLoaderSelector)

  const dispatch = useDispatch()

  const updateUser = (data) => {
    const { day, month, year, postCode } = data
    const formatData = data
    formatData.postCode = postCode.toString()
    if (day && month && year) {
      formatData.birthday = new Date(`${year}/${month}/${day}`)
    }

    return dispatch(actions.user.update.process(formatData))
  }
  const months = [
    { name: 'janvier', value: '01' },
    { name: 'février', value: '02' },
    { name: 'mars', value: '03' },
    { name: 'avril', value: '04' },
    { name: 'mai', value: '05' },
    { name: 'juin', value: '06' },
    { name: 'juillet', value: '07' },
    { name: 'août', value: '08' },
    { name: 'septembre', value: '09' },
    { name: 'octobre', value: '10' },
    { name: 'novembre', value: '11' },
    { name: 'décembre', value: '12' },
  ]
  const getYears = () => {
    const years = []
    for (
      let i = 0;
      i < (new Date().getFullYear() - 100 - new Date().getFullYear()) * -1;
      // eslint-disable-next-line no-plusplus
      i++
    ) {
      let newYear = new Date().getFullYear() - 100
      newYear += i
      years.push({ name: newYear, value: newYear })
    }
    return years
  }
  const getDays = () => {
    const days = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 31; i++) {
      let day = 1
      day += i
      days.push({ name: day, value: day < 10 ? `0${day}` : `${day}` })
    }
    return days
  }

  const [userPicture, setUserPicture] = useState(null)
  const [image, setImage] = useState(null)

  const addImgHandler = (file) => {
    if (!file) return
    setImage(file.target.files[0])
    const reader = new FileReader()
    reader.readAsDataURL(file.target.files[0])
    reader.onload = () => {
      setUserPicture(reader.result)
    }
  }

  const sendPicture = () => dispatch(actions.user.profilePicture.process(image))

  useEffect(() => {
    if (userLoader) {
      setImage(null)
      setUserPicture(null)
    }
  }, [userLoader])

  return (
    <FormCard customStyle={{ padding: 0 }}>
      {userLoader ? (
        <Box display="flex" justifyContent="center">
          <Loader w={200} height={200} display="flex" />
        </Box>
      ) : (
        <>
          <Stack spacing={5} mb={12}>
            <Box as="h2" textStyle="h2">
              Infos personnelles
            </Box>
            <Box display="flex" justifyContent="center">
              {user?.profilePicture?.thumbnailSizeUrl ? (
                <Image
                  borderRadius="full"
                  boxSize={100}
                  bg="white"
                  borderColor="primary.400"
                  src={user.profilePicture.thumbnailSizeUrl}
                  alt={`image de ${user.firstname}`}
                  border={userPicture && `4px solid ${theme.red[400]}`}
                />
              ) : (
                <Box
                  background="secondary.400"
                  borderRadius="full"
                  ml={4}
                  w={100}
                  h={100}
                  boxShadow="2px 4px 0px 0px rgba(0, 0, 0, 0.08)"
                />
              )}
              {userPicture && (
                <>
                  <AnimateArrow
                    position="relative"
                    display="flex"
                    flexDir="column"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="center"
                    p={4}
                  >
                    <FaBack color={theme.green[400]} className="arrow__left" />
                    <FaRight color={theme.red[400]} className="arrow__right" />
                  </AnimateArrow>
                  <Box position="relative">
                    <Image
                      borderRadius="full"
                      boxSize={100}
                      bg="white"
                      src={userPicture}
                      alt={`image de ${user.firstname}`}
                      border={`4px solid ${theme.green[400]}`}
                    />
                    <Box
                      position="absolute"
                      right="-20px"
                      top="-5px"
                      fontSize={20}
                      color="rgba(54, 53, 55, 0.4)"
                      _hover={{
                        cursor: 'pointer',
                        transform: 'scale(1.2) rotate(0.24turn)',
                        transition: '0.5s ease',
                      }}
                      p={0}
                      onClick={() => setUserPicture(null)}
                    >
                      <FaCross />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
            <Box display="flex" justifyContent="center">
              {userPicture ? (
                <Button onClick={() => sendPicture()}>Valider</Button>
              ) : (
                <FormLabel
                  id="add-img-label"
                  htmlFor="add-single-img"
                  color="primary.400"
                  textDecoration="underline"
                  type="submit"
                  fontWeight={900}
                  textAlign="center"
                  padding={4}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  Modifier ma photo de profil
                  <input
                    type="file"
                    id="add-single-img"
                    accept="image/jpeg, image/png"
                    style={{ display: 'none' }}
                    onChange={(e) => addImgHandler(e)}
                  />
                </FormLabel>
              )}
            </Box>
          </Stack>

          <Form
            onSubmit={updateUser}
            initialValues={{
              ...user,
              day: new Date(user.birthday).toLocaleDateString('fr-fr', {
                day: '2-digit',
              }),
              month: new Date(user.birthday).toLocaleDateString('fr-fr', {
                month: '2-digit',
              }),
              year: new Date(user.birthday).toLocaleDateString('fr-fr', {
                year: 'numeric',
              }),
            }}
            render={({ handleSubmit, pristine, valid, values }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={8}>
                  <Field
                    name="phoneNumber"
                    component={InputText}
                    label="Téléphone"
                    RightIcon={FaLock}
                    disabled
                  />
                  <Field
                    name="firstName"
                    component={InputText}
                    validate={composeValidators(required)}
                    label="Prénom"
                  />
                  <Field
                    name="lastName"
                    component={InputText}
                    validate={composeValidators(required)}
                    label="Nom"
                  />
                  <Field
                    name="email"
                    component={InputText}
                    validate={composeValidators(email)}
                    label="E-mail (optionnel)"
                  />
                  <Box textAlign="left">
                    <RadioGroup value={values.gender}>
                      <Stack spacing={5} direction="row">
                        <Field
                          name="gender"
                          component={InputRadio}
                          validate={composeValidators(required)}
                          type="radio"
                          value="male"
                          label="Mr"
                          id="male"
                          colorScheme="primary"
                        />
                        <Field
                          name="gender"
                          component={InputRadio}
                          validate={composeValidators(required)}
                          type="radio"
                          value="female"
                          label="Mme"
                          id="female"
                          colorScheme="primary"
                        />
                      </Stack>
                    </RadioGroup>
                  </Box>
                  <Box
                    as="h3"
                    textStyle="h3"
                    fontWeight={700}
                    color="primary.400"
                    textAlign="left"
                    fontSize={12}
                    mt={3}
                    mb={3}
                  >
                    DATE DE NAISSANCE{' '}
                    <Box as="span" textStyle="p" color="grey">
                      (optionnel)
                    </Box>
                  </Box>
                  <HStack spacing={3}>
                    <Field
                      name="day"
                      component={InputSelect}
                      validate={composeValidators()}
                      placeholder="Jour"
                      labelValueNull="Jour"
                      options={getDays()}
                    />
                    <Field
                      name="month"
                      component={InputSelect}
                      validate={composeValidators()}
                      placeholder="Mois"
                      labelValueNull="Mois"
                      options={months}
                    />
                    <Field
                      name="year"
                      component={InputSelect}
                      validate={composeValidators()}
                      placeholder="Année"
                      labelValueNull="Année"
                      options={getYears()}
                    />
                  </HStack>
                  <Box
                    as="h3"
                    textStyle="h3"
                    fontWeight={700}
                    color="primary.400"
                    textAlign="left"
                    fontSize={12}
                    mt={3}
                    mb={3}
                  >
                    ADRESSE
                  </Box>
                  <Field
                    name="street"
                    component={InputText}
                    validate={composeValidators(required)}
                    label="Nom et  numéro de rue"
                  />
                  <Field
                    name="postCode"
                    component={InputText}
                    validate={composeValidators(required, postalCode)}
                    label="Code postal"
                  />
                  <Field
                    name="city"
                    component={InputText}
                    validate={composeValidators(required)}
                    label="City"
                  />
                  <Box>
                    <Button type="submit" w="50%" disabled={pristine || !valid}>
                      Valider
                    </Button>
                  </Box>
                </Stack>
              </form>
            )}
          />
        </>
      )}
    </FormCard>
  )
}

export default Index
