import { createReducer } from '@reduxjs/toolkit'
import { catchErrorSuccess, catchErrorRemove } from './actions'

const INITIAL_STATE = {
  errorList: {},
}

export const errorReducer = createReducer(INITIAL_STATE, {
  [catchErrorSuccess.type]: (state, action) => ({
    ...state,
    errorList: action.payload.message,
  }),
  [catchErrorRemove]: (state) => INITIAL_STATE,
})
