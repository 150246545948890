import { selectors } from '../../utils'

const { mergeErrorsSelector } = selectors

export const checkPhoneErrorSelector = (state) =>
  state.session.errors.checkPhone

export const getUserTokenErrorSelector = (state) =>
  state.session.errors.getUserToken

export const errorsSelector = mergeErrorsSelector([
  checkPhoneErrorSelector,
  getUserTokenErrorSelector,
])
