import React from 'react'
import { Box } from '@chakra-ui/react'
import { FormCardWrapper } from '../../styles/components/cards/formCard'

export const FormCard = ({ children, customStyle }) => (
  <FormCardWrapper style={customStyle}>
    <Box className="form__wrapper">
      <Box flex={1}> {children}</Box>
    </Box>
  </FormCardWrapper>
)
