import React from 'react'
import { Field, Form } from 'react-final-form'
import { Box, Button, Link, Stack } from '@chakra-ui/react'
import { InputPin } from '../../../../components/form'
import { composeValidators, required } from '../../../../helpers/fieldValidator'
import { FormCard } from '../../../../components/cards'
import { BackButton } from '../../../../components'

const Index = ({ buttonContent, callback, resendCode, prev }) => (
  <>
    <BackButton callback={() => prev()} />
    <FormCard>
      <Stack spacing={4}>
        <Box as="h2" textStyle="h2">
          Code de vérification
        </Box>
        <Box as="p" textStyle="p">
          Entrez le code que vous venez de recevoir <br />
          par SMS.
        </Box>
        <Form
          onSubmit={callback}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <Field
                  name="code"
                  component={InputPin}
                  validate={composeValidators(required)}
                />
                <Link
                  as="a"
                  textStyle="a"
                  textDecoration="underline"
                  onClick={resendCode}
                  color="primary.400"
                  style={{ marginBottom: '50px' }}
                >
                  renvoyer le code
                </Link>
                <Button type="submit">{buttonContent}</Button>
              </Stack>
            </form>
          )}
        />
      </Stack>
    </FormCard>
  </>
)

export default Index
