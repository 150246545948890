import * as React from 'react'

const SvgMeteoBrokenClouds = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.703 7.967c.665.631 1.15 1.45 1.368 2.372a3.003 3.003 0 0 1 3.721 2.691c.93.204 1.72.783 2.202 1.57h1.31a1.465 1.465 0 0 0 0-2.93h-.037a.75.75 0 0 1-.735-.9A1.096 1.096 0 0 0 17.8 9.673a.75.75 0 0 1-1.2-.6 2.573 2.573 0 0 0-4.897-1.106Zm7.784 8.133h.817a2.965 2.965 0 0 0 .734-5.84 2.596 2.596 0 0 0-3.08-2.258 4.075 4.075 0 0 0-7.502-.89 4.8 4.8 0 0 0-6.85 4.102 4.351 4.351 0 0 0 .744 8.636h11.7a3.45 3.45 0 0 0 3.437-3.75ZM1.5 15.5a2.85 2.85 0 0 1 2.85-2.85h.022a.75.75 0 0 0 .748-.832 3.3 3.3 0 1 1 6.58-.368.75.75 0 0 0 1.2.6 1.5 1.5 0 0 1 2.37 1.501.75.75 0 0 0 .735.899h.045a1.95 1.95 0 1 1 0 3.9H4.35A2.85 2.85 0 0 1 1.5 15.5Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMeteoBrokenClouds
