import React from 'react'
import { Box, Button, Stack } from '@chakra-ui/react'
import { Field, Form } from 'react-final-form'
import { InputPhone } from '../../../../components/form'
import { composeValidators, required } from '../../../../helpers/fieldValidator'
import { normalizePhone } from '../../../../helpers/formatPhoneNumber'
import { FormCard } from '../../../../components/cards'

const Index = ({ callBack }) => (
  <FormCard>
    <Stack spacing={4}>
      <Box as="h2" textStyle="h2">
        Prêt à embarquer ?
      </Box>
      <Box as="p" textStyle="p">
        Que vous ayez un compte, ou pas encore, <br />
        entrez votre numéro de mobile
      </Box>
      <Form
        onSubmit={callBack}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <Field
                name="phoneNumber"
                component={InputPhone}
                validate={composeValidators(required)}
                parse={normalizePhone}
                placeholder="XX XX XX XX XX"
              />
              <Button type="submit">Commencer</Button>
            </Stack>
          </form>
        )}
      />
    </Stack>
  </FormCard>
)

export default Index
