import React from 'react'
import { Box, Stack } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../store'
import { selectors } from '../../store/selectors'
import { formatPhoneNumber } from '../../helpers/formatPhoneNumber'
import {
  BillingInformations,
  InviterInformations,
  PhoneNumber,
  SubscriptionInformations,
  ValidationCode,
} from './formStep'
import { onBoardingStepper } from '../../helpers/onBoardingStepper'
import { OnBoardingWrapper } from '../../styles/onBoarding'
import { LogoIndex } from '../../components/Logo'

const Index = () => {
  const onBoarding = useSelector(selectors.session.onBoardingSelector)
  const savedPhoneNumber = useSelector(selectors.session.phoneNumberSelector)

  const dispatch = useDispatch()
  const checkPhone = ({ phoneNumber }) =>
    dispatch(
      actions.session.checkPhone.process({
        phoneNumber: formatPhoneNumber(phoneNumber),
      })
    )

  const getUserToken = ({ code }) =>
    dispatch(actions.session.getUserToken.process({ code }))

  const pay = (data) => dispatch(actions.session.pay.process(data))

  const changeStep = (step) =>
    dispatch(actions.session.onBoardingStep.process(step))

  const switchForm = () => {
    switch (onBoarding.onBoardingStep) {
      case onBoardingStepper.billingInformations:
        return (
          <BillingInformations
            next={() => changeStep(onBoardingStepper.validationCode)}
            prev={() => changeStep(onBoardingStepper.phoneNumber)}
          />
        )
      case onBoardingStepper.inviterInformations:
        return (
          <InviterInformations
            inviter={onBoarding.inviter}
            next={() =>
              dispatch(
                actions.session.initiateVerification.process({
                  phoneNumber: savedPhoneNumber,
                  step: onBoardingStepper.validationCode,
                })
              )
            }
            prev={() => changeStep(onBoardingStepper.phoneNumber)}
          />
        )
      case onBoardingStepper.subscriptionInformations:
        return (
          <SubscriptionInformations
            callback={(value) => pay(value)}
            prev={() => changeStep(onBoardingStepper.validationCode)}
          />
        )
      case onBoardingStepper.validationCode:
        return (
          <ValidationCode
            buttonContent={
              !onBoarding.pendingRegistration
                ? 'Connexion'
                : 'Activer mon compte'
            }
            callback={(value) => getUserToken(value)}
            resendCode={() =>
              dispatch(
                actions.session.checkPhone.process({
                  phoneNumber: onBoarding.phoneNumber,
                })
              )
            }
            prev={() => {
              if (onBoarding.inviter) {
                return changeStep(onBoardingStepper.inviterInformations)
              }
              return changeStep(onBoardingStepper.phoneNumber)
            }}
          />
        )
      default:
        return <PhoneNumber callBack={checkPhone} />
    }
  }

  return (
    <OnBoardingWrapper>
      <Box className="onboarding__baseline">
        <Stack spacing={3}>
          <LogoIndex />
          <Box as="h1" textStyle="h1">
            L’application qui <br />
            reconnecte les générations
          </Box>
        </Stack>
      </Box>
      <>{switchForm()}</>
    </OnBoardingWrapper>
  )
}

export default Index
