import { put } from 'redux-saga/effects'
import { currentContact } from '../actions'

export default () =>
  // eslint-disable-next-line consistent-return
  function* currentContactSaga({ payload }) {
    try {
      yield put(currentContact.loading())
      yield put(currentContact.success(payload))
    } catch (error) {
      yield put(currentContact.error({ error }))
    }
  }
