import { paths } from './paths'
import PrivateRoute from '../PrivateRoute'
import Wall from '../../views/Wall'

const routes = [
  {
    path: paths.WALL,
    element: <PrivateRoute />,
    children: [
      {
        path: paths.WALL,
        element: <Wall />,
      },
    ],
  },
]

export default routes
