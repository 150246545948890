import * as React from 'react'

const SvgFaPictures = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <circle cx={25} cy={25} r={25} fill="#6891FB" fillOpacity={0.16} />
    <path
      d="M36.25 15h-19.5a.75.75 0 0 0-.75.75V18h-2.25a.75.75 0 0 0-.75.75v15c0 .414.336.75.75.75h19.5a.75.75 0 0 0 .75-.75V31.5h2.25a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75Zm-3.75 4.5v11.87l-4.012-3.44a.75.75 0 0 0-.874-.073l-3.212 1.928-4.838-5.529a.75.75 0 0 0-1.094-.036l-3.97 3.97V19.5h18Zm-18 10.81 4.463-4.462 4.723 5.397c.24.273.639.336.95.148l3.286-1.971L32.098 33H14.5v-2.69Zm21-.31H34V18.75a.75.75 0 0 0-.75-.75H17.5v-1.5h18V30Z"
      fill="currentColor"
    />
    <path
      d="M28.375 26.25A2.628 2.628 0 0 0 31 23.625 2.628 2.628 0 0 0 28.375 21a2.628 2.628 0 0 0-2.625 2.625 2.628 2.628 0 0 0 2.625 2.625Zm0-3.75c.62 0 1.125.505 1.125 1.125s-.505 1.125-1.125 1.125-1.125-.505-1.125-1.125.505-1.125 1.125-1.125Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaPictures
