import _ from 'lodash'

export default ({ name, actions, payloadKey, payloadDefaultValue = null }) => {
  const initialState = {
    errors: {
      [name]: null,
    },
    loading: {
      [name]: false,
    },
  }

  if (payloadKey) {
    initialState[payloadKey] = payloadDefaultValue
  }

  const actionsMap = {
    [actions.loading]: (state) => {
      const newState = _.cloneDeep(state)
      newState.loading[name] = true
      newState.errors[name] = null
      return newState
    },
    [actions.success]: (state, action) => {
      const newState = _.cloneDeep(state)
      newState.loading[name] = false
      newState.errors[name] = null
      if (payloadKey) {
        newState[payloadKey] = action.payload[payloadKey]
      }
      return newState
    },
    [actions.error]: (state, action) => {
      const newState = _.cloneDeep(state)
      newState.loading[name] = false
      newState.errors[name] = action.error
      return newState
    },
  }

  return { initialState, actionsMap }
}
