import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { Box, Button } from '@chakra-ui/react'
import { CallWrapper } from '../Call'

const Index = () => {
  const currentContact = useOutletContext()
  return (
    <CallWrapper>
      <Box as="h2" textStyle="h2">
        Prendre une photo/vidéo depuis votre ordinateur
        <br />à {currentContact.firstName}
      </Box>
      <Box as="p" textStyle="p" color="primary.400">
        Choisissez le fichier à envoyer depuis votre ordinateur
      </Box>
      <Button colorScheme="lightblue">Parcourir</Button>
    </CallWrapper>
  )
}

export default Index
