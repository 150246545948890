import React, { useState, useEffect } from 'react'
import { Box } from '@chakra-ui/react'

const Index = () => {
  const [clock, setClock] = useState(new Date())
  useEffect(() => {
    const interval = setInterval(() => setClock(new Date()), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Box as="h1" textStyle="h1" fontSize={80} color="white">
      {clock.getHours()}:
      {(clock.getMinutes() < 10 ? '0' : '') + clock.getMinutes()}
    </Box>
  )
}

export default Index
