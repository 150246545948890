import React from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Link as ChakraLink,
  useDisclosure,
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { paths } from '../../../router/paths'
import { FaBack, FaCross } from '../../../components/Svg'
import { actions } from '../../../store'
import { selectors } from '../../../store/selectors'
import Loader from '../../../components/Loader'
import { legalsLink } from '../../../helpers/legalsLinks'

const Index = () => {
  const dispatch = useDispatch()
  const deleteAccountLoader = useSelector(
    selectors.user.deleteAccountLoaderSelector
  )
  const deleteAccount = () => dispatch(actions.user.deleteAccount.process())

  const pathsParameters = [
    {
      to: paths.USER_INFORMATIONS,
      name: 'Infos personnelles',
    },
    // {
    //   to: paths.SUBSCRIPTION,
    //   name: 'Mon abonnement',
    // },
    // {
    //   to: paths.INTERESTS,
    //   name: 'Mes centres d’interêt',
    // },
    // {
    //   to: paths.NOTIFICATIONS,
    //   name: 'Notifications',
    // },
    // {,
  ]
  const pathsHelps = [
    // {
    //   to: paths.FAQ,
    //   name: 'FAQ',
    // },
    {
      to: paths.CONTACT,
      name: 'Contactez-nous',
    },
  ]

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(54, 53, 55, 0.4);" />
        <ModalContent borderRadius={16} p={3}>
          <ModalHeader />
          <ModalCloseButton
            borderRadius="full"
            boxShadow="2px 4px 0 rgba(0, 0, 0, 0.08)"
            p={3}
            color="rgba(54, 53, 55, 0.4)"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <Box>
              <FaCross />
            </Box>
          </ModalCloseButton>
          <ModalBody>
            <Stack spacing={4}>
              {deleteAccountLoader ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Loader w={200} h={200} />
                </Box>
              ) : (
                <>
                  <Box
                    as="h3"
                    textStyle="h3"
                    textAlign="center"
                    color="primary.400"
                  >
                    Supression du compte
                  </Box>
                  <Box as="p" textStyle="p" textAlign="center">
                    Êtes-vous sûr de vouloir supprimer <br />
                    votre compte ?
                  </Box>
                </>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              bg="linear-gradient(0deg, rgba(54, 53, 55, 0.06), rgba(54, 53, 55, 0.06)), #FFFFFF;"
              _hover={{ bg: '#ddd' }}
              color="paragraph"
              mr={3}
              onClick={() => deleteAccount()}
            >
              Je supprime mon compte
            </Button>
            <Button mr={3} onClick={onClose}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box as="h3" textStyle="h3">
        Paramètres du compte
      </Box>
      <Box className="link__group">
        {pathsParameters.map((link) => (
          <NavLink
            to={link.to}
            className={({ isActive }) =>
              isActive ? 'link active_link' : 'link'
            }
          >
            <span>{link.name}</span>
            <FaBack style={{ transform: 'rotate(180deg)' }} />
          </NavLink>
        ))}
        <Box className="link delete__account" onClick={onOpen}>
          <span>Supprimer mon compte</span>
          <FaBack style={{ transform: 'rotate(180deg)' }} />
        </Box>
      </Box>
      <Box as="h3" textStyle="h3">
        Assistance
      </Box>
      <Box className="link__group">
        {pathsHelps.map((link) => (
          <NavLink
            to={link.to}
            className={({ isActive }) =>
              isActive ? 'link active_link' : 'link'
            }
          >
            <span>{link.name}</span>
            <FaBack style={{ transform: 'rotate(180deg)' }} />
          </NavLink>
        ))}
      </Box>
      <Box as="h3" textStyle="h3">
        Confidentialité
      </Box>
      <ChakraLink
        className="link"
        href={legalsLink.confidentiality}
        target="_blank"
      >
        Paramètres de confidentialité{' '}
        <FaBack style={{ transform: 'rotate(180deg)' }} />
      </ChakraLink>
      <ChakraLink className="link" href={legalsLink.CGU} target="_blank">
        CGU <FaBack style={{ transform: 'rotate(180deg)' }} />
      </ChakraLink>
      {/* <ChakraLink className="link" href={legalsLink.CGV} target="_blank"> */}
      {/*  CGV <FaBack style={{ transform: 'rotate(180deg)' }} /> */}
      {/* </ChakraLink> */}
      <Box display="flex" justifyContent="space-between" p={3} mt={23}>
        <Box
          onClick={() => dispatch(actions.session.logout.process())}
          textStyle="a"
          textDecoration="underline"
          cursor="pointer"
        >
          Déconnexion
        </Box>{' '}
        version {'{{}}'}
      </Box>
    </>
  )
}

export default Index
