import api from '../../services/api'
import { sagas } from '../../utils'
import { sendMedia as actions } from '../actions'

export default () =>
  sagas.createDefaultSaga({
    apiCall: api.media.sendAudio,
    actions,
    payloadKey: 'sendMedia',
  })
