import { put } from 'redux-saga/effects'
import { onBoardingStep } from '../actions'

export default () =>
  // eslint-disable-next-line consistent-return
  function* setOnBoardingStep({ payload }) {
    try {
      yield put(onBoardingStep.loading())
      yield put(onBoardingStep.success(payload))
    } catch (error) {
      yield put(onBoardingStep.error({ error }))
    }
  }
