import React, { useEffect } from 'react'
import { Box, useToast } from '@chakra-ui/react'
import { FaCheckIllu, FaCrossIllu, FaHeartIllu } from '../Svg'
import { availableIcon } from '../../store/toast/helpers/toastStatus'

// helperToColor
const bgColor = {
  success: 'rgb(210,242,234)',
  error: 'rgb(255,234,230)',
  info: '#fff',
}

const RenderToasts = ({ toast, removeToast, icon }) => {
  const toastr = useToast()

  // eslint-disable-next-line consistent-return
  const renderIcon = () => {
    switch (toast.icon) {
      case availableIcon.SUCCESS:
        return <FaCheckIllu />
      case availableIcon.ERROR:
        return <FaCrossIllu />
      case availableIcon.HEART:
        return <FaHeartIllu />
      default:
        return <FaCrossIllu />
    }
  }

  useEffect(() => {
    if (toast) {
      toastr({
        icon,
        title: toast.title,
        description: toast.description,
        status: toast.status,
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {
          removeToast(toast.id)
        },
        render: () => (
          <Box
            borderRadius={12}
            bg={bgColor[toast.status]}
            p={6}
            display="flex"
            alignItems="center"
          >
            <Box fontSize={50} mr={3}>
              {renderIcon()}
            </Box>
            <Box as="h3" textStyle="h3">
              {toast.title}
            </Box>
          </Box>
        ),
      })
    }
  }, [toast])
  return <div />
}

const index = ({ toasts, removeToast }) => (
  <>
    {toasts.map((toast) => (
      <RenderToasts
        icon={<FaCheckIllu />}
        key={toast.id}
        toast={toast}
        removeToast={removeToast}
      />
    ))}
  </>
)

export default index
