import styled from 'styled-components'
import { StyledAccountWrapper } from '../Account'

export const StyledContactWrapper = styled(StyledAccountWrapper)`
  .icon__sidebar {
    font-size: 50px;
    margin-right: 12px;
  }
  .icon__sidebar {
    font-size: 50px;
    svg {
      circle {
        fill: transparent;
      }
    }
  }
  .active_link {
    svg {
      circle {
        fill: #6891fb;
      }
    }
  }
`
