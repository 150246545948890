import { createReducer } from '@reduxjs/toolkit'
import { reset, success, errors, deleteById } from '../actions'

const INITIAL_STATE = {
  message: [],
}

const toastReducer = createReducer(INITIAL_STATE, {
  [success.type]: (state, action) => ({
    ...state,
    message: [...state.message, action.payload],
  }),
  [errors.type]: (state, action) => ({
    ...state,
    message: [...state.message, action.payload],
  }),
  [deleteById.type]: (state, action) => ({
    message: state.message.filter((e) => e.id !== action.payload),
  }),
  [reset.type]: (state) => ({
    ...state,
    message: [],
  }),
})

export default toastReducer
