import { request } from '../../http'

export default () =>
  async ({ data, headers }) =>
    request({
      route: `user/profilePicture`,
      method: 'PUT',
      data: { file: data },
      dataType: 'form-data',
      headers,
    })
