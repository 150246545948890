import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { Box, FormControl, FormLabel, useColorMode } from '@chakra-ui/react'
import styled from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'

const CustomDatePicker = styled(DatePicker)`
  background: ${(props) =>
    props.colorMode === 'dark'
      ? `${props.theme.dark.background[50]}`
      : `${props.theme.light.background[50]}`} !important;
  border: 1px solid white !important;
  border-radius: 4px;
  color: ${(props) =>
    props.colorMode === 'dark' ? 'white' : 'black'} !important;
  ::placeholder {
    color: ${(props) =>
      props.colorMode === 'dark' ? 'red' : 'yellow'} !important;
  }
  padding: 5px;
  width: 100%;
`

export const InputDate = ({ input: { onChange }, meta, ...rest }) => {
  const [startDate, setStartDate] = useState(new Date())
  onChange(startDate)
  const { colorMode } = useColorMode()
  return (
    <>
      <FormControl>
        {rest.label && <FormLabel>{rest.label}</FormLabel>}
        <CustomDatePicker
          colorMode={colorMode}
          selected={startDate}
          onChange={(date) => {
            setStartDate(date)
            return onChange(date)
          }}
          {...rest}
        />
      </FormControl>
      {(meta.error || meta.submitError) && meta.touched && (
        <Box as="p" fontWeight="700" color="red.400" textAlign="left">
          {meta.error || meta.submitError}
        </Box>
      )}
    </>
  )
}
