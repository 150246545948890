import { takeLatest, put } from 'redux-saga/effects'
import { catchError, catchErrorSuccess, catchErrorRemove } from './actions'

// eslint-disable-next-line no-promise-executor-return
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

function* displayToastr(action) {
  yield put(catchErrorSuccess(action.payload))
  yield delay(2000)
  yield put(catchErrorRemove())
}

export function* watchErrors() {
  yield takeLatest(catchError.type, displayToastr)
}
