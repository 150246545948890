import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  checkPhone,
  reset,
  getUserToken,
  onBoardingStep,
  initiateVerification,
  pay,
} from '../actions'
import { reducers } from '../../utils'
import { onBoardingStepper } from '../../../helpers/onBoardingStepper'

export const INITIAL_STATE = {
  token: null,
  onBoarding: {
    onBoardingStatus: null,
    onBoardingStep: null,
    phoneChecked: false,
    phoneNumber: null,
    pendingRegistration: null,
    inviter: null,
  },
  errors: {
    checkPhone: null,
    pay: null,
    login: null,
    initiateVerification: null,
  },
  loading: {
    checkPhone: false,
    pay: false,
    login: false,
    initiateVerification: false,
  },
}

export default createReducer(INITIAL_STATE, {
  [checkPhone.loading]: (state) => {
    const newState = _.cloneDeep(state)
    newState.loading.checkPhone = true
    newState.errors.checkPhone = null
    return newState
  },
  [checkPhone.success]: (state, action) => {
    const newState = _.cloneDeep(state)
    newState.onBoarding = action.payload
    newState.loading.checkPhone = false
    return newState
  },
  [checkPhone.error]: (state, action) => {
    const newState = _.cloneDeep(state)
    newState.onBoarding = {
      phoneChecked: false,
      phoneNumber: undefined,
      pendingRegistration: undefined,
      inviter: undefined,
    }
    newState.errors.checkPhone = action.error
    newState.loading.checkPhone = false
    return newState
  },
  [getUserToken.loading]: (state) => {
    const newState = _.cloneDeep(state)
    newState.loading.login = true
    return newState
  },
  [getUserToken.success]: (state, action) => {
    const newState = _.cloneDeep(state)
    newState.token = action.payload.accessToken
    newState.onBoarding.onBoardingStep = onBoardingStepper.connected
    newState.loading.login = false
    return newState
  },
  [getUserToken.error]: (state, action) => {
    const newState = _.cloneDeep(state)
    newState.token = null
    newState.errors.login = action.error
    newState.loading.login = false
    return newState
  },
  [initiateVerification.loading]: (state) => {
    const newState = _.cloneDeep(state)
    newState.loading.login = true
    return newState
  },
  [pay.loading]: (state) => {
    const newState = _.cloneDeep(state)
    newState.loading.pay = true
    return newState
  },
  [pay.success]: (state) => {
    const newState = _.cloneDeep(state)
    newState.loading.pay = false
    return newState
  },
  [pay.error]: (state, action) => {
    const newState = _.cloneDeep(state)
    newState.errors.pay = action.error
    newState.loading.pay = false
    return newState
  },
  [onBoardingStep.success]: (state, action) => {
    const newState = _.cloneDeep(state)
    newState.onBoarding.onBoardingStep = action.payload
    return newState
  },
  [reset]: (state, action) =>
    reducers.resetState(state, INITIAL_STATE, action.payload.keys),
})
