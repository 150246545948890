import { createBrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import landingPage from './landingPage'
import onBoarding from './onBoarding'
import wall from './wall'
import account from './account'
import download from './download'
import contact from './contact'

const SentryRoutes = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = SentryRoutes([
  ...landingPage,
  ...onBoarding,
  ...wall,
  ...account,
  ...download,
  ...contact,
])
