import { request } from '../../http'

export default () =>
  async ({ data }) => {
    const { phoneNumber } = data
    return request({
      route: `/sms/initiate-verification?phoneNumber=${phoneNumber}`,
      method: 'POST',
    })
  }
