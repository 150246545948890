import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import { theme } from '../../themeConfig'

export const StyledLeftWrapper = styled(Box)`
  color: white;
  h3 {
    margin: 40px 0;
  }
  h3:nth-child(1) {
    margin-top: 0;
  }
  .link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 27px;
    border-bottom: 1px solid rgba(244, 240, 227, 0.4);

    &:hover {
      color: ${theme.lightblue[500]};
      text-decoration: none;
    }
  }
  .link:nth-child(1) {
    border-top: 1px solid rgba(244, 240, 227, 0.4);
  }
  .active_link {
    background: #ffffff;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
    border-radius: 16px;

    color: ${theme.lightblue[500]};
    &:hover {
      background: #ffffff !important;
    }
  }
  .delete__account {
    cursor: pointer;
    &:hover {
      color: ${theme.lightblue[500]};
    }
  }
`
