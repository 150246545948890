import styled from 'styled-components'
import { Box } from '@chakra-ui/react'

export const LandingPageWrapper = styled(Box)`
  display: flex;
  height: 100vh;

  .onboarding__baseline {
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    flex: 2;
    h1 {
      font-weight: 600;
      text-transform: uppercase;
      line-height: 30px;
      font-size: 22px;
    }
    svg {
      margin: 0 auto;
      width: 311px;
    }
  }
  .circle {
    background: white;
    z-index: 1;

    height: 150vh;
    width: 150vh;
    border-radius: 50%;
    position: fixed;
    top: -25vh;
    left: 40%;
  }
  .slider__landing__page {
    flex: 3;
  }

  .baseline__slider {
    z-index: 3;
    display: block;
    position: relative;
    text-align: center;
  }
`

export const LottieWrapper = styled(Box)`
  z-index: 900;
  position: relative;
  height: 650px;
  .background,
  .foreground {
    position: absolute;
    width: 550px;
    top: 100px;
    left: calc(50% - (550px / 2));
  }
`
