import React, { useState, useRef, useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import _ from 'lodash'
import { FaPause, FaMic, FaPlayer } from '../../../../components/Svg'

const AudioPlayer = ({ audio }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)

  const audioPlayer = useRef()

  useEffect(() => {
    audioPlayer.current.addEventListener(
      'loadedmetadata',
      () => {
        function getDuration() {
          audioPlayer.current.currentTime = 0
          audioPlayer.current.removeEventListener('timeupdate', getDuration)
          setDuration(Math.floor(audioPlayer.current.duration))
        }

        if (audioPlayer.current.duration === Infinity) {
          audioPlayer.current.currentTime = 1e101
          audioPlayer.current.addEventListener('timeupdate', getDuration)
        }
      },
      false
    )
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState])

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60)
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
    const seconds = Math.floor(secs % 60)
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${returnedMinutes}:${returnedSeconds} sec`
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying
    setIsPlaying(!prevValue)
    if (!prevValue) {
      audioPlayer.current.play()
    } else {
      audioPlayer.current.pause()
      setIsPlaying(false)
    }
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioPlayer} src={audio} preload="metadata" />
      <Box
        display="flex"
        flex={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          h="50px"
          w="50px"
          borderRadius="full"
          background="#6891FB20"
          display="flex"
          justifyContent="center"
          alignItems="center"
          color="lightblue.400"
          fontSize={40}
        >
          <FaMic />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box mr={3} color="lightblue.400">
            {duration && !_.isNaN(duration) && calculateTime(duration)}
          </Box>
          <Box
            h="50px"
            w="50px"
            borderRadius="full"
            background="lightblue.400"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="white"
            fontSize={20}
            onClick={() => {
              togglePlayPause()
            }}
            cursor="pointer"
            pl={isPlaying ? 0 : 1}
          >
            {isPlaying ? <FaPause /> : <FaPlayer />}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export { AudioPlayer }
