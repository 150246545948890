import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { router } from './router'
import Toaster from './components/Toaster'
import { actions } from './store'
import { selectors } from './store/selectors'

const App = () => {
  const dispatch = useDispatch()
  const toasts = useSelector(selectors.toast.toastsSelector)
  const removeToast = (toastId) => dispatch(actions.toast.deleteById(toastId))

  return (
    <>
      <Toaster
        toasts={toasts}
        removeToast={(toastId) => removeToast(toastId)}
      />
      <RouterProvider router={router} />
    </>
  )
}

export default App
