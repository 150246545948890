import { v4 as uuidv4 } from 'uuid'

import { errors } from '../../actions'
import { toastStatus } from '../../helpers'
import { media } from '../../../actions'

export default {
  [media.sendAudio.error.type]: ({ next, action }) => {
    next(
      errors({
        id: uuidv4(),
        status: toastStatus.ERROR,
        title: "Une erreur s'est produite lors de l'envoie du message audio !",
        description: '',
      })
    )
    next(action)
  },
  [media.sendMedia.error.type]: ({ next, action }) => {
    next(
      errors({
        id: uuidv4(),
        status: toastStatus.ERROR,
        title: "Une erreur s'est produite lors de l'envoie du média !",
        description: '',
      })
    )
    next(action)
  },
}
