import { call, put, cancelled, select } from 'redux-saga/effects'
import { checkPhone } from '../actions'
import api from '../../services/api'
import { onBoardingStepper } from '../../../helpers/onBoardingStepper'

// because api return 404 instead of undefined on checkPhoneNumber
let savedPhoneNumber

export default () =>
  // eslint-disable-next-line consistent-return
  function* authenticate({ payload }) {
    try {
      const { phoneNumber } = payload
      savedPhoneNumber = phoneNumber
      yield put(checkPhone.loading())
      const { inviter, pendingRegistration } = yield call(
        api.auth.checkPhone(),
        {
          data: { phoneNumber },
        }
      )

      if (!pendingRegistration) {
        yield put(
          checkPhone.success({
            onBoardingStep: onBoardingStepper.validationCode,
            phoneChecked: true,
            phoneNumber,
            pendingRegistration,
            inviter: null,
          })
        )
        yield call(api.auth.initiateVerification(), {
          data: { phoneNumber, step: null },
        })
      }
      if (inviter && pendingRegistration) {
        yield put(
          checkPhone.success({
            onBoardingStep: onBoardingStepper.inviterInformations,
            phoneChecked: true,
            phoneNumber,
            pendingRegistration,
            inviter,
          })
        )
      }

      if (!inviter && pendingRegistration) {
        yield put(
          checkPhone.success({
            onBoardingStep: onBoardingStepper.validationCode,
            phoneChecked: true,
            phoneNumber,
            pendingRegistration,
            inviter,
          })
        )
        return yield call(api.auth.initiateVerification(), {
          data: {
            phoneNumber,
            step: null,
          },
        })
      }
    } catch (error) {
      if (
        error.data.statusCode === 404 ||
        error.data.message === 'Cannot find user'
      ) {
        yield put(
          checkPhone.success({
            onBoardingStep: onBoardingStepper.billingInformations,
            phoneChecked: true,
            phoneNumber: savedPhoneNumber,
            pendingRegistration: null,
            inviter: null,
          })
        )
        const state = yield select()
        return yield call(api.auth.initiateVerification(), {
          data: {
            phoneNumber: state.session.onBoarding.phoneNumber,
            step: null,
          },
        })
      }
      yield put(checkPhone.error({ error }))
    } finally {
      if (yield cancelled()) {
        /* NOTHING TO DO */
      }
    }
  }
