import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  reset as resetAction,
  currentContact,
  getAllByStatus,
} from '../actions'
import get from './get'

import { reducers as reducerHelpers } from '../../utils'

const INITIAL_STATE = _.merge(
  {
    currentContact: null,
    getAll: null,
    errors: {
      getAll: null,
    },
    loading: {
      getAll: false,
    },
  },
  get.initialState
)

const actionsMap = _.merge(
  {
    [currentContact.success]: (state, action) => {
      const newState = _.cloneDeep(state)
      newState.currentContact = action.payload
      return newState
    },
    [getAllByStatus.loading]: (state) => {
      const newState = _.cloneDeep(state)
      newState.getAll = null
      newState.loading.getAll = true
      return newState
    },
    [getAllByStatus.success]: (state, action) => {
      const newState = _.cloneDeep(state)
      newState.getAll = action.payload
      newState.loading.getAll = false
      return newState
    },
    [getAllByStatus.error]: (state) => {
      const newState = _.cloneDeep(state)
      newState.getAll = null
      newState.loading.getAll = false
      return newState
    },
  },
  get.actionsMap,
  {
    [resetAction]: (state, action) =>
      reducerHelpers.resetState(state, INITIAL_STATE, action.payload.keys),
  }
)

export default createReducer(INITIAL_STATE, actionsMap)
