import { call, put } from 'redux-saga/effects'
import { profilePicture, me, reset } from '../actions'
import api from '../../services/api'
import createDefaultHeaders from '../../utils/sagas/createDefaultHeaders'

export default () =>
  // eslint-disable-next-line consistent-return
  function* profilePictureSaga({ payload }) {
    try {
      yield put(profilePicture.loading())
      yield call(api.user.profilePicture(), {
        data: payload,
        headers: yield createDefaultHeaders(true),
      })
      yield put(me.process())
      yield put(profilePicture.success({ profilePicture: true }))
      yield put(reset({ keys: ['profilePicture'] }))
    } catch (error) {
      yield put(profilePicture.error({ error }))
    }
  }
