import { request } from '../../http'

export default () =>
  async ({ data, headers }) =>
    request({
      route: `/invitation/invite`,
      method: 'POST',
      headers,
      data,
    })
