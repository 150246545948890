import * as React from 'react'

const SvgFaCheckIllu = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M11.52 6.517a1 1 0 1 0 1.18 1.615l-1.18-1.615ZM25 4.125a1 1 0 1 0 0-2v2ZM2.125 25a1 1 0 1 0 2 0h-2Zm3.232-7.083a1 1 0 0 0-1.881-.678l1.88.678Zm7.342-9.785C16.15 5.612 20.4 4.125 25 4.125v-2c-5.039 0-9.7 1.63-13.48 4.392l1.18 1.615ZM4.125 25c0-2.488.435-4.872 1.232-7.083l-1.881-.678A22.835 22.835 0 0 0 2.125 25h2ZM38.48 43.483a1 1 0 1 0-1.18-1.615l1.18 1.615ZM25 45.875a1 1 0 1 0 0 2v-2ZM47.875 25a1 1 0 1 0-2 0h2Zm-3.232 7.083a1 1 0 1 0 1.882.678l-1.882-.678Zm-7.342 9.785A20.776 20.776 0 0 1 25 45.875v2c5.039 0 9.7-1.63 13.48-4.392l-1.18-1.615ZM45.875 25c0 2.488-.435 4.872-1.232 7.083l1.882.678A22.835 22.835 0 0 0 47.875 25h-2Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 42.188c9.492 0 17.188-7.696 17.188-17.188 0-9.492-7.696-17.188-17.188-17.188v34.376Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 42.188c-9.492 0-17.188-7.696-17.188-17.188 0-9.492 7.696-17.188 17.188-17.188v34.376Z"
      fill="#F4F0E3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.294 31.776c.608.57 1.603.52 2.143-.11l9.83-11.451a1.33 1.33 0 0 0-.207-1.937c-.62-.48-1.534-.392-2.04.198l-8.807 10.287-3.338-3.13a1.504 1.504 0 0 0-2.05.011 1.328 1.328 0 0 0 .012 1.947l4.457 4.186"
      fill="#65C5A9"
    />
  </svg>
)
export default SvgFaCheckIllu
