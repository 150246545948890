import React, { useEffect, useState } from 'react'
import { Box, Button, Link, Stack } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import IllustrationCarteBancaire from '../../../styles/Account/Subscription/illustrations/IllustrationCarteBancaire'
import { AccountSubsriptionWrapper } from '../../../styles/Account/Subscription'
import { selectors } from '../../../store/selectors'
import { FaDownload } from '../../../components/Svg'
import { actions } from '../../../store'
import Loader from '../../../components/Loader'

const RenderBillingRow = ({ bill, ...props }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    borderTop="1px solid #D7CBA5"
    p={6}
    {...props}
  >
    <Box as="p" textStyle="p" color="blue.400">
      Facture du {new Date(bill.period_end * 1000).toLocaleDateString('fr')}
    </Box>
    <Box as="p" textStyle="p" color="blue.400" fontWeight={700}>
      {bill.amount_paid}€
    </Box>

    <Link href={bill.hosted_invoice_url} target="_blank" rel="noreferrer">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={50}
        width={50}
        bg="white"
        color="primary.400"
        _hover={{ bg: '#ddd' }}
        borderRadius="full"
        p={0}
        variant="link"
      >
        <FaDownload width="20px" height="20px" />
      </Box>
    </Link>
  </Box>
)

const Index = () => {
  const user = useSelector(selectors.user.userSelector)
  const bills = useSelector(selectors.payment.billsSelector)
  const billsLoader = useSelector(selectors.payment.billsLoaderSelector)

  const [limitBill, setLimitBill] = useState(true)

  const dispatch = useDispatch()
  const getBills = () => dispatch(actions.payment.bills.process())

  useEffect(() => {
    getBills()
  }, [])

  return (
    <AccountSubsriptionWrapper bg="#F4F0E3">
      <AccountSubsriptionWrapper bg="white" w="100%" p={20}>
        <Box as="h2" textStyle="h2" mb={4}>
          Abonnement premium
        </Box>

        <Box as="p" textStyle="p" color="primary.400" mb={8}>
          Prochain prélèvement : 12,95€, le{' '}
          {new Date(
            `${user.actual_subscription.nextBillingDate}`
          ).toLocaleDateString('fr')}
        </Box>
        <Box className="illustration__card">
          <IllustrationCarteBancaire />
          <Box as="p" textStyle="p" fontWeight="700" className="user">
            {user.fullName}
          </Box>
          <Box as="p" textStyle="p" fontWeight="700" className="date">
            11/25
          </Box>
        </Box>
        <Button mt={8}>Changer de moyen de paiement</Button>
      </AccountSubsriptionWrapper>

      <Stack spacing={8} p="40px 110px">
        {billsLoader ? (
          <Box display="flex" justifyContent="center">
            <Loader w={200} height={200} display="flex" />
          </Box>
        ) : (
          <>
            <Box as="h2" textStyle="h2" m={8}>
              Abonnement premium
            </Box>
            {bills ? (
              <>
                {limitBill
                  ? bills
                      .slice(0, 4)
                      .map((bill) => (
                        <RenderBillingRow key={bill.id} bill={bill} />
                      ))
                  : bills.map((bill) => (
                      <RenderBillingRow key={bill.id} bill={bill} />
                    ))}
                {bills.length > 4 && (
                  <Box>
                    <Button onClick={() => setLimitBill(!limitBill)}>
                      {limitBill
                        ? 'Voir toutes mes factures'
                        : 'Afficher les 4 dernières facture'}
                    </Button>
                  </Box>
                )}

                <Link
                  color="primary.400"
                  fontWeight="bold"
                  textTransform="underline"
                  textDecoration="underline"
                >
                  Suspendre mon abonnement
                </Link>
              </>
            ) : (
              <>pas de bill</>
            )}

            <Box as="p" textStyle="p" mb={8}>
              Si votre abonnement est suspendu, vous ne bénéficiez plus des
              avantages premium.
            </Box>
          </>
        )}
      </Stack>
    </AccountSubsriptionWrapper>
  )
}

export default Index
