import { v4 as uuidv4 } from 'uuid'

import { errors } from '../../actions'
import { toastStatus } from '../../helpers'
import { invitation } from '../../../actions'

export default {
  [invitation.send.error.type]: ({ next, action }) => {
    next(
      errors({
        id: uuidv4(),
        status: toastStatus.ERROR,
        title: "Une erreur s'est produite lors de l'envoie de l'invitation !",
        description: '',
      })
    )
    next(action)
  },
  [invitation.deleteInvitation.error.type]: ({ next, action }) => {
    next(
      errors({
        id: uuidv4(),
        status: toastStatus.ERROR,
        title:
          "Une erreur s'est produite lors de la suppression de l'invitation !",
        description: '',
      })
    )
    next(action)
  },
  [invitation.accept.error.type]: ({ next, action }) => {
    next(
      errors({
        id: uuidv4(),
        status: toastStatus.ERROR,
        title:
          "Une erreur s'est produite lors de l'acceptation de la demande !",
        description: '',
      })
    )
    next(action)
  },
}
