import { takeEvery } from 'redux-saga/effects'
import * as actions from '../actions'
import updateSaga from './update'
import me from './me'
import deleteAccount from './deleteAccount'
import profilePicture from './profilePicture'

export default () =>
  function* UserSagas() {
    yield takeEvery(actions.update.process.type, updateSaga())
    yield takeEvery(actions.me.process.type, me())
    yield takeEvery(actions.profilePicture.process.type, profilePicture())
    yield takeEvery(actions.deleteAccount.process.type, deleteAccount())
  }
