import { selectors } from '../../utils'

const { mergeErrorsSelector } = selectors

export const updateErrorSelector = (state) => state.user.errors.updated
export const meErrorSelector = (state) => state.user.errors.user
export const profilePictureErrorSelector = (state) =>
  state.user.errors.profilePicture
export const deleteAccountErrorSelector = (state) =>
  state.user.errors.profilePicture

export const errorsSelector = mergeErrorsSelector([
  updateErrorSelector,
  meErrorSelector,
  profilePictureErrorSelector,
  deleteAccountErrorSelector,
])
