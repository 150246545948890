import { v4 as uuidv4 } from 'uuid'
import { media } from '../../../actions'
import { success } from '../../actions'
import { toastStatus } from '../../helpers'
import { availableIcon } from '../../helpers/toastStatus'

export default {
  [media.sendAudio.success.type]: ({ next, action }) => {
    next(
      success({
        id: uuidv4(),
        icon: availableIcon.SUCCESS,
        status: toastStatus.SUCCESS,
        title: 'Votre message audio a bien été envoyée !',
        description: '',
      })
    )
    next(action)
  },
  [media.sendMedia.success.type]: ({ next, action }) => {
    next(
      success({
        id: uuidv4(),
        icon: availableIcon.SUCCESS,
        status: toastStatus.SUCCESS,
        title: 'Votre media a bien été envoyée !',
        description: '',
      })
    )
    next(action)
  },
}
