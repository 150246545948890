import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Box, Button, FormLabel, Image } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { CallWrapper } from '../Call'
import { FaPictures } from '../../../../components/Svg'
import { actions } from '../../../../store'
import { selectors } from '../../../../store/selectors'
import Loader from '../../../../components/Loader'

const Index = () => {
  const currentContact = useOutletContext()
  const sentMedia = useSelector(selectors.media.sendMediaSelector)
  const sendMediaLoader = useSelector(selectors.media.sendMediaLoaderSelector)
  const [mediaFile, setMediaFile] = useState(null)
  // eslint-disable-next-line no-shadow
  const [media, setMedia] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (sentMedia) {
      setMedia(null)
      setMediaFile(null)
      dispatch(actions.media.reset({ key: ['sendMedia'] }))
    }
  }, [sentMedia])

  const sendMedia = async () => {
    dispatch(
      actions.media.sendMedia.process({
        recipientUuid: currentContact.uuid,
        file: media,
      })
    )
  }

  const addImgHandler = (file) => {
    if (!file) return
    setMedia(file.target.files[0])
    const reader = new FileReader()
    reader.readAsDataURL(file.target.files[0])
    reader.onload = () => {
      setMediaFile(reader.result)
    }
  }

  return (
    <CallWrapper>
      <Box as="h2" textStyle="h2">
        Envoyer une photo/vidéo <br />à {currentContact.firstName}
      </Box>
      {sendMediaLoader ? (
        <Loader />
      ) : (
        <>
          <Box as="p" textStyle="p" color="primary.400">
            {mediaFile
              ? `Votre ${
                  media.type.split('/')[0] === 'image' ? 'photo' : 'vidéo'
                } est prête à être envoyée ?`
              : 'Choisissez le fichier à envoyer depuis votre ordinateur'}
          </Box>
          {mediaFile && media ? (
            <Box
              p={3}
              bg="lightblue.50"
              minW="400px"
              minH="122px"
              borderRadius={8}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className="audio__wrapper"
            >
              <Box display="flex" alignItems="center" gap={4}>
                {media.type.split('/')[0] === 'image' ? (
                  <Image
                    borderRadius={4}
                    boxSize={100}
                    bg="white"
                    src={mediaFile}
                    alt="image"
                    objectFit="cover"
                  />
                ) : (
                  <Box
                    borderRadius={4}
                    h={100}
                    w={100}
                    color="white"
                    fontSize={100}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bg="lightblue.400"
                  >
                    <FaPictures />
                  </Box>
                )}
                <Box
                  textStyle="h3"
                  as="h3"
                  fontSize={15}
                  fontWeight={500}
                  color="primary.400"
                >
                  {media.name}
                </Box>
              </Box>
              <FormLabel
                color="lightblue.400"
                id="add-img-label"
                htmlFor="add-single-img"
                textDecoration="underline"
                type="submit"
                fontWeight={700}
                textAlign="center"
                m={1}
                style={{
                  cursor: 'pointer',
                }}
              >
                Modifier
                <input
                  type="file"
                  id="add-single-img"
                  accept="image/jpeg, image/png, video/*"
                  style={{ display: 'none' }}
                  onChange={(e) => addImgHandler(e)}
                />
              </FormLabel>
            </Box>
          ) : (
            <FormLabel
              id="add-img-label"
              htmlFor="add-single-img"
              color="white"
              type="submit"
              fontWeight={700}
              textAlign="center"
              padding={4}
              style={{
                cursor: 'pointer',
              }}
            >
              <Box
                textStyle="Button"
                bg="primary.500"
                p={3}
                pr={5}
                pl={5}
                borderRadius="full"
                boxShadow="2px 4px 0 rgba(0, 0, 0, 0.08)"
                _hover={{ bg: 'primary.600' }}
              >
                Parcourir
              </Box>
              <input
                type="file"
                id="add-single-img"
                accept="image/jpeg, image/png, video/*"
                style={{ display: 'none' }}
                onChange={(e) => addImgHandler(e)}
              />
            </FormLabel>
          )}
          {mediaFile && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={4}
            >
              <Button
                variant="unstyled"
                color="lightblue.400"
                onClick={() => setMediaFile(null)}
              >
                Annuler
              </Button>
              <Button
                colorScheme="lightblue"
                onClick={() => sendMedia(mediaFile)}
              >
                Envoyer
              </Button>
            </Box>
          )}
        </>
      )}
    </CallWrapper>
  )
}

export default Index
