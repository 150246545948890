import React from 'react'
import { Box } from '@chakra-ui/react'
import { useLottie } from 'lottie-react'
import lottieLoader from './loader_small.json'

const Index = (props) => {
  const loader = {
    animationData: lottieLoader,
    loop: true,
  }

  const { View: Loader } = useLottie(loader)
  return <Box {...props}>{Loader}</Box>
}

export default Index
