import { request } from '../../http'

export default () =>
  async ({ data, headers }) =>
    request({
      route: `/media/share`,
      method: 'POST',
      data,
      dataType: 'form-data',
      headers,
    })
