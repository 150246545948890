export const toastStatus = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
}

export const availableIcon = {
  SUCCESS: 'CHECK',
  ERROR: 'CROSS',
  HEART: 'HEART',
}
