import { paths } from './paths'
import PrivateRoute from '../PrivateRoute'
import UserInformations from '../../views/Account/UserInformations'
import Account from '../../views/Account'
import Subscription from '../../views/Account/Subscription'
import Contact from '../../views/Account/Contact'

const routes = [
  {
    path: paths.ACCOUNT,
    element: <PrivateRoute withBackgroundImage={false} />,
    children: [
      {
        path: paths.ACCOUNT,
        element: <Account />,
        children: [
          {
            path: paths.USER_INFORMATIONS,
            element: <UserInformations />,
          },
          {
            path: paths.SUBSCRIPTION,
            element: <Subscription />,
          },
          {
            path: paths.CONTACT,
            element: <Contact />,
          },
        ],
      },
    ],
  },
]

export default routes
