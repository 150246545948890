import * as React from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  PinInput,
  PinInputField,
} from '@chakra-ui/react'

export const InputPin = ({
  files,
  input,
  meta,
  onChange,
  placeholder,
  invalid,
  ...rest
}) => (
  <>
    <FormControl>
      {rest.label && <FormLabel>{rest.label} </FormLabel>}
      <Box display="flex" justifyContent="center" mt={4} mb={4}>
        <HStack>
          <PinInput placeholder="-" {...input} borderColor="#F4F0E3" size="lg">
            <PinInputField borderColor="#F4F0E3" />
            <PinInputField borderColor="#F4F0E3" />
            <PinInputField borderColor="#F4F0E3" />
            <PinInputField borderColor="#F4F0E3" />
          </PinInput>
        </HStack>
      </Box>
    </FormControl>
    {(meta.error || meta.submitError) && meta.touched && (
      <Box as="p" fontWeight="700" color="red.400" textAlign="left">
        {meta.error || meta.submitError}
      </Box>
    )}
  </>
)
