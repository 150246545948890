export * from './errors'
export * from './loading'

export const isAuthenticatedSelector = (state) => state.session.tokens !== null
export const downloadAccessTokenSelector = (state) =>
  state.session.tokens.downloadAccessToken
export const connectedUserSelector = (state) => state.session.connectedUser
export const onBoardingSelector = (state) => state.session.onBoarding
export const tokenSelector = (state) => state.session.token
export const phoneNumberSelector = (state) =>
  state.session.onBoarding.phoneNumber
