import * as React from 'react'

const SvgFaTrashRed = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <circle cx={25} cy={25} r={25} fill="#DC3F1A" fillOpacity={0.08} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m33.517 18.4-1.04 16.35c-.08 1.265-1.15 2.25-2.44 2.25H19.963c-1.29 0-2.36-.985-2.44-2.25l-1.04-16.35h-1.566a.909.909 0 0 1-.917-.9c0-.497.41-.9.917-.9h20.166c.507 0 .917.403.917.9s-.41.9-.917.9h-1.566Zm-1.95 0H18.433l.88 15.666a1.213 1.213 0 0 0 1.22 1.134h8.931c.65 0 1.185-.498 1.221-1.134l.88-15.666ZM25 20.2c.472 0 .855.376.855.84v11.52c0 .464-.383.84-.855.84a.848.848 0 0 1-.856-.84V21.04c0-.464.383-.84.856-.84Zm-3.667 0c.479 0 .873.37.894.84l.537 11.58a.751.751 0 0 1-.759.78.833.833 0 0 1-.837-.779l-.644-11.582a.801.801 0 0 1 .81-.839Zm7.333 0a.803.803 0 0 1 .81.839l-.64 11.522a.897.897 0 0 1-.903.84.803.803 0 0 1-.809-.84l.64-11.522a.897.897 0 0 1 .902-.839Zm-6.11-7.2h4.888c1.35 0 2.445 1.074 2.445 2.4v1.2H20.11v-1.2c0-1.326 1.094-2.4 2.444-2.4Zm.121 1.8a.727.727 0 0 0-.733.72v1.08h6.11v-1.08a.727.727 0 0 0-.732-.72h-4.645Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaTrashRed
