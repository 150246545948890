import * as React from 'react'

const SvgMoonWaxingGibous = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <circle cx={12} cy={12} r={10} fill="#D7CBA5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2C8.686 2 6 6.477 6 12s2.686 10 6 10Z"
      fill="#363537"
      fillOpacity={0.5}
    />
  </svg>
)
export default SvgMoonWaxingGibous
