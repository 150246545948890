import React, { useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectors } from '../../store/selectors'
import { paths } from '../paths'
import { onBoardingStepper } from '../../helpers/onBoardingStepper'
import { Container, Navbar } from '../../components/layout'
import { actions } from '../../store'

const Index = ({ withNavigation = true, withBackgroundImage = true }) => {
  const token = useSelector(selectors.session.tokenSelector)
  const onBoarding = useSelector(selectors.session.onBoardingSelector)
  const user = useSelector(selectors.user.userSelector)
  const contacts = useSelector(selectors.contact.contactsSelector)
  const contactLoader = useSelector(selectors.contact.getLoadingSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.contact.get.process({ state: 'validated' }))
    dispatch(actions.user.me.process())
  }, [])

  if (
    user?.status !== 'active' &&
    !token &&
    onBoarding.onBoardingStep !== onBoardingStepper.connected
  )
    return <Navigate replace to={paths.ON_BOARDING} />
  return (
    <Container withBackgroundImage={withBackgroundImage}>
      {withNavigation && (
        <Navbar user={user} contacts={{ contacts, contactLoader }} />
      )}
      <Outlet />
    </Container>
  )
}

export default Index
