import * as React from 'react'

const SvgFaPlayer = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 27 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.589.146a1.126 1.126 0 0 1 1.16.03l24.38 15.505c.335.212.538.585.538.986 0 .4-.203.773-.538.986L1.75 33.157a1.126 1.126 0 0 1-1.16.03A1.166 1.166 0 0 1 0 32.17V1.163C0 .74.225.35.589.146ZM2.286 3.26v26.812l21.081-13.406L2.287 3.26Z"
      fill="#fff"
    />
  </svg>
)

export default SvgFaPlayer
