import { call, put } from 'redux-saga/effects'
import { update, me } from '../actions'
import api from '../../services/api'
import createDefaultHeaders from '../../utils/sagas/createDefaultHeaders'

export default () =>
  // eslint-disable-next-line consistent-return
  function* updateSaga({ payload }) {
    try {
      yield put(update.loading())
      const {
        firstName,
        lastName,
        birthday,
        email,
        gender,
        city,
        postCode,
        street,
      } = payload

      yield call(api.user.update(), {
        data: {
          firstName,
          lastName,
          birthday,
          email,
          gender,
          city,
          postCode,
          street,
        },
        headers: yield createDefaultHeaders(true),
      })
      yield put(me.process())
      yield put(update.success({ updatedUser: true }))
    } catch (error) {
      yield put(update.error({ error }))
    }
  }
