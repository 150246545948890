import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { paths } from '../../../../router/paths'
import { theme } from '../../../../styles/themeConfig'

const baseNavLink = {
  fontSize: '16px',
  fontWeight: 700,
  borderRadius: '50px',
  padding: '16px 24px',
  margin: '0 5px',
  height: '54px',
}

const pathsNavLinks = [
  {
    name: 'Accueil',
    path: paths.WALL,
  },
  {
    name: 'Souvenirs',
    path: paths.ON_BOARDING,
  },
  {
    name: 'Services',
    path: paths.ON_BOARDING,
  },
  {
    name: 'Santé',
    path: paths.ON_BOARDING,
  },
  {
    name: 'Loisirs',
    path: paths.ON_BOARDING,
  },
]

const Index = () => (
  <Box>
    {pathsNavLinks.map((link) => (
      <NavLink
        key={link.name}
        to={link.path}
        style={({ isActive }) =>
          isActive
            ? {
                backgroundColor: 'white',
                color: theme.primary['400'],
                boxShadow: '2px 4px 0px rgba(0, 0, 0, 0.08)',
                ...baseNavLink,
              }
            : {
                color: 'white',
                ...baseNavLink,
              }
        }
      >
        {link.name}
      </NavLink>
    ))}
  </Box>
)

export default Index
