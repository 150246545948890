import {
  MeteoBrokenClouds,
  MeteoClearSky,
  MeteoFewClouds,
  MeteoMist,
  MeteoRain,
  MeteoScatteredClouds,
  MeteoShowerRain,
  MeteoSnow,
  MeteoThunderstorm,
} from '../../../../components/Svg'

export const getCloudIcon = (cloud) => {
  switch (cloud) {
    case '01d':
    case '01n':
      return <MeteoClearSky />
    case '02d':
    case '02n':
      return <MeteoFewClouds />
    case '03d':
    case '03n':
      return <MeteoScatteredClouds />
    case '04d':
    case '04n':
      return <MeteoBrokenClouds />
    case '09d':
    case '09n':
      return <MeteoShowerRain />
    case '10d':
    case '10n':
      return <MeteoRain />
    case '11d':
    case '11n':
      return <MeteoThunderstorm />
    case '13d':
    case '13n':
      return <MeteoSnow />
    case '50d':
    case '50n':
      return <MeteoMist />
    default:
      return <MeteoClearSky />
  }
}
