import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
import backgroundApp from '../../images/onBoarding/backgroundApp.png'

export const OnBoardingWrapper = styled(Box)`
  background-image: linear-gradient(
      180deg,
      rgba(46, 38, 35, 0.7) 0%,
      rgba(46, 38, 35, 0) 50%,
      rgba(46, 38, 35, 0) 50%,
      #2e2623 100%
    ),
    url(${backgroundApp});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 100vh;
  padding: 50px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 12px;

  position: relative;

  .onboarding__baseline {
    margin-top: 400px;
    grid-column: 1/3;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    h1 {
      font-weight: 600;
      text-transform: uppercase;
      line-height: 30px;
      font-size: 22px;
    }
    svg {
      margin: 0 auto;
      width: 311px;
    }
  }
`
