import { paths } from './paths'
import OnBoarding from '../../views/OnBoarding'
import PublicRoute from '../PublicRoute'

const routes = [
  {
    path: paths.ON_BOARDING,
    element: <PublicRoute />,
    children: [
      {
        path: paths.ON_BOARDING,
        element: <OnBoarding />,
      },
    ],
  },
]

export default routes
