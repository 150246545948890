import { v4 as uuidv4 } from 'uuid'
import { user } from '../../../actions'
import { success } from '../../actions'
import { toastStatus } from '../../helpers'
import { availableIcon } from '../../helpers/toastStatus'

export default {
  [user.update.success.type]: ({ next, action }) => {
    next(
      success({
        id: uuidv4(),
        icon: availableIcon.SUCCESS,
        status: toastStatus.SUCCESS,
        title: 'Votre profil a bien été mis à jour !',
        description: '',
      })
    )
    next(action)
  },
}
