import { takeEvery } from 'redux-saga/effects'
import * as actions from '../actions'
import get from './get'
import getAllByStatus from './getAllByStatus'
import currentContact from './currentContact'

export default () =>
  function* contactSagas() {
    yield takeEvery(actions.get.process.type, get())
    yield takeEvery(actions.getAllByStatus.process.type, getAllByStatus())
    yield takeEvery(actions.currentContact.process.type, currentContact())
  }
