export const paths = {
  ACCOUNT: '/account',
  USER_INFORMATIONS: '/account/user-informations',
  SUBSCRIPTION: '/account/subscription',
  INTERESTS: '/account/interests',
  NOTIFICATIONS: '/account/notifications',
  DELETE_ACCOUNT: '/account/delete-account',
  FAQ: '/account/faq',
  CONTACT: '/account/contact-us',
  PRIVACY_SETTINGS: '/account/privacy-settings',
}
