import React, { useEffect } from 'react'
import { useGeolocated } from 'react-geolocated'
import { Box, Center, Divider } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { FaCalendar } from '../../../components/Svg'
import { actions } from '../../../store'
import { selectors } from '../../../store/selectors'
import Clock from './Clock'
import Date from './Date'
import { weatherIcons } from './helpers'
import Loader from '../../../components/Loader'

const Index = () => {
  const dispatch = useDispatch()
  const currentWeather = useSelector(selectors.weather.getSelector)
  const currentWeatherLoader = useSelector(selectors.weather.getLoaderSelector)
  const currentEphemeride = useSelector(selectors.weather.getEphemerideSelector)
  const weatherToday = currentWeather?.weather_days[0]

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })

  const getGeolocalisation = () => {
    if (coords?.latitude && coords?.longitude) {
      return dispatch(
        actions.weather.get.process({
          lat: coords.latitude,
          long: coords.longitude,
        })
      )
    }
    return dispatch(actions.weather.get.process())
  }

  useEffect(() => {
    getGeolocalisation()
    dispatch(actions.weather.getEphemeride.process())
  }, [])

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {currentWeatherLoader ? (
        <Loader h={50} w={50} />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {currentWeather && (
            <Box display="flex" flexDir="column" alignItems="center" flex={1}>
              <Box>
                <Box as="h3" textStyle="h3" color="white" mb={10} flex={1}>
                  Aujourd'hui
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" width="100%">
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  pr={4}
                  flex={1}
                >
                  <Box mt={3} mr={4} textAlign="right">
                    <Date />
                    <Box as="h3" textStyle="h3" color="white" mt={3}>
                      {currentEphemeride}
                    </Box>
                  </Box>
                  <Box as="h3" textStyle="h3" color="white" fontSize={28}>
                    <FaCalendar />
                  </Box>
                </Box>
                <Clock />
                <Box display="flex" alignItems="flex-start" pl={4} flex={1}>
                  <Box as="h3" textStyle="h3" color="white" fontSize={32}>
                    {weatherIcons.getCloudIcon(weatherToday.weather_icon)}
                  </Box>
                  <Box ml={4} flex={1}>
                    <Box display="flex" alignItems="center">
                      <Box as="h3" textStyle="h3" color="white">
                        Max{' '}
                        <Box as="span" fontSize={20} fontWeight={900}>
                          {Math.round(weatherToday.temp_max)}°C
                        </Box>
                      </Box>
                      <Center height="36px" ml={3} mr={3}>
                        <Divider orientation="vertical" />
                      </Center>
                      <Box as="h3" textStyle="h3" color="white">
                        <Box as="span" fontSize={20} fontWeight={900}>
                          {Math.round(weatherToday.temp_min)}°C
                        </Box>{' '}
                        Min
                      </Box>
                    </Box>
                    <Box as="h3" textStyle="h3" color="white">
                      {weatherToday.weather_description}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" mt={3}>
                <Box as="h3" textStyle="h3" color="white" fontSize={32} mr={4}>
                  {weatherIcons.getMoonIcon(weatherToday.moon_icon)}
                </Box>
                <Box as="h3" textStyle="h3" color="white">
                  {weatherToday.moon_description}
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default Index
