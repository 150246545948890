import React from 'react'
import _ from 'lodash'
import { Navigate } from 'react-router-dom'
import { paths } from '../../router/paths'

const Index = () => {
  if (navigator?.platform === 'iPhone') {
    return window.location.replace(
      'https://apps.apple.com/app/index-tv/id6503435209/',
    )
  }
  if (_.includes(navigator.userAgent, 'Android')) {
    return window.location.replace(
      'https://play.google.com/store/apps/details?id=com.indextv',
    )
  }
  return <Navigate to={paths.ON_BOARDING} />
}

export default Index
