import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { reset as resetAction } from '../actions'

import login from './landingPageSeen'

import { reducers as reducerHelpers } from '../../utils'

const INITIAL_STATE = _.merge({}, login.initialState)

const actionsMap = _.merge({}, login.actionsMap, {
  [resetAction]: (state, action) =>
    reducerHelpers.resetState(state, INITIAL_STATE, action.payload.keys),
})

export default createReducer(INITIAL_STATE, actionsMap)
