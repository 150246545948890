import { paths } from './paths'
import DownloadApp from '../../views/DownloadApp'

const routes = [
  {
    path: paths.DOWNLOAD,
    element: <DownloadApp />,
  },
]

export default routes
