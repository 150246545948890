import * as React from 'react'

const SvgMeteoClearSky = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 .25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0V1A.75.75 0 0 1 12 .25ZM.25 12a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5H1A.75.75 0 0 1 .25 12Zm20 0a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM12 20.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75Zm0-2.75a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Zm0 1.5a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM4.752 3.691a.75.75 0 0 0-1.06 1.061l1.414 1.414a.75.75 0 0 0 1.06-1.06L4.752 3.69Zm15.556 1.061a.75.75 0 1 0-1.06-1.06l-1.414 1.414a.75.75 0 1 0 1.06 1.06l1.415-1.414ZM6.166 18.894a.75.75 0 0 0-1.06-1.06L3.69 19.248a.75.75 0 0 0 1.061 1.06l1.414-1.414Zm12.728-1.06a.75.75 0 0 0-1.06 1.06l1.414 1.415a.75.75 0 0 0 1.06-1.061l-1.414-1.414Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMeteoClearSky
