import { selectors } from '../../utils'

const { mergeErrorsSelector } = selectors

export const invitationErrorSelector = (state) => state.invitation.errors.send
export const acceptErrorSelector = (state) => state.invitation.errors.accept
export const deleteInvitationErrorSelector = (state) =>
  state.invitation.errors.deleteInvitation

export const errorsSelector = mergeErrorsSelector([invitationErrorSelector])
