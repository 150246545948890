import { call, put } from 'redux-saga/effects'
import api from '../../services/api'
import { deleteAccount } from '../actions'
import createDefaultHeaders from '../../utils/sagas/createDefaultHeaders'
import { logout } from '../../session/actions'

export default () =>
  function* deleteAccountSaga() {
    try {
      yield put(deleteAccount.loading())
      yield call(api.user.deleteAccount(), {
        headers: yield createDefaultHeaders(true),
      })
      yield put(logout.process())
      yield put(deleteAccount.success())
    } catch (error) {
      yield put(deleteAccount.error({ error }))
    }
  }
