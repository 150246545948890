import * as React from 'react'

const SvgFaPause = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M7.82 23H5.323C4.042 23 3 21.984 3 20.739V4.261C3 3.015 4.043 2 5.322 2h2.48c1.278 0 2.321 1.016 2.321 2.261v16.478C10.123 22.004 9.08 23 7.821 23ZM5.323 3.954a.34.34 0 0 0-.335.325v16.479a.34.34 0 0 0 .335.325h2.48a.34.34 0 0 0 .334-.325V4.26a.34.34 0 0 0-.335-.326h-2.48v.02ZM18.939 23h-2.48c-1.278 0-2.321-1.016-2.321-2.261V4.261C14.138 3.015 15.18 2 16.46 2h2.479c1.279 0 2.322 1.016 2.322 2.261v16.478C21.24 22.004 20.218 23 18.939 23ZM16.44 3.954a.34.34 0 0 0-.335.325v16.479a.34.34 0 0 0 .335.325h2.48a.34.34 0 0 0 .334-.325V4.26a.34.34 0 0 0-.335-.326H16.44v.02Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaPause
