import createAction from './createAction'

const createDefaultActions = (domain, actionName) => ({
  process: createAction(`${domain}/${actionName}`),
  loading: createAction(`${domain}/${actionName}:LOADING`),
  success: createAction(`${domain}/${actionName}:SUCCESS`),
  error: createAction(`${domain}/${actionName}:ERROR`),
})

export default createDefaultActions
