import React from 'react'
import { Box } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { FaBack, FaMic, FaPictures, FaVideo } from '../../../../components/Svg'
import { paths } from '../../../../router/paths'
import Avatar from '../../../../components/Images/Avatar'

const Index = ({ user }) => (
  <>
    <Box className="sidebar__thumbnail">
      <Avatar
        src={user.profilePicture.thumbnailSizeUrl}
        boxSize="128px"
        mb={4}
      />
      <Box fontWeight={700}>{user.firstName}</Box>
    </Box>

    <NavLink
      to={paths.CALL}
      className={({ isActive }) => (isActive ? 'link active_link' : 'link')}
    >
      <Box display="flex" alignItems="center">
        <Box className="icon__sidebar">
          <FaVideo />
        </Box>
        <span>Appeler en visio</span>
      </Box>
      <FaBack style={{ transform: 'rotate(180deg)' }} />
    </NavLink>
    {/* <NavLink */}
    {/*  to={paths.TAKE_MEDIA} */}
    {/*  className={({ isActive }) => (isActive ? 'link active_link' : 'link')} */}
    {/* > */}
    {/*  <Box display="flex" alignItems="center"> */}
    {/*    <Box className="icon__sidebar"> */}
    {/*      <FaPhoto /> */}
    {/*    </Box> */}
    {/*    <span>Prendre une photo / vidéo</span> */}
    {/*  </Box> */}
    {/*  <FaBack style={{ transform: 'rotate(180deg)' }} /> */}
    {/* </NavLink> */}
    <NavLink
      to={paths.SEND_MEDIA}
      className={({ isActive }) => (isActive ? 'link active_link' : 'link')}
    >
      <Box display="flex" alignItems="center">
        <Box className="icon__sidebar">
          <FaPictures />
        </Box>
        <span>
          Envoyer une photo / vidéo <br />
          depuis ma bibliothèque
        </span>
      </Box>
      <FaBack style={{ transform: 'rotate(180deg)' }} />
    </NavLink>
    <NavLink
      to={paths.SEND_AUDIO}
      className={({ isActive }) => (isActive ? 'link active_link' : 'link')}
    >
      <Box display="flex" alignItems="center">
        <Box className="icon__sidebar">
          <FaMic />
        </Box>
        <span>Envoyer un message vocal</span>
      </Box>
      <FaBack style={{ transform: 'rotate(180deg)' }} />
    </NavLink>
  </>
)

export default Index
