import styled from 'styled-components'
import { Box } from '@chakra-ui/react'

export const FormCardWrapper = styled(Box)`
  grid-column: 3/5;
  align-self: center;
  padding: 80px;
  .form__wrapper {
    position: relative;
    border-radius: 16px;
    background: white;
    padding: 80px 120px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`
