import { call, put } from 'redux-saga/effects'
import { onBoardingStep, pay } from '../actions'
import api from '../../services/api'
import createDefaultHeaders from '../../utils/sagas/createDefaultHeaders'
import { onBoardingStepper } from '../../../helpers/onBoardingStepper'
import { me } from '../../user/actions'

export default () =>
  // eslint-disable-next-line consistent-return
  function* paySaga({ payload }) {
    try {
      yield put(pay.loading())
      yield call(api.payment.pay(), {
        data: payload,
        headers: yield createDefaultHeaders(true),
      })
      yield put(pay.success(payload))
      yield put(me.process())
      yield put(onBoardingStep.process(onBoardingStepper.connected))
    } catch (error) {
      yield put(pay.error({ error }))
    }
  }
