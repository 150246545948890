import * as React from 'react'

const SvgFaLock = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M2.824 8.35V4.677C2.824 2.094 5.14 0 8 0s5.176 2.096 5.176 4.677V8.35h1.05c.98 0 1.774.833 1.774 1.854v9.282c0 1.024-.793 1.854-1.773 1.854H1.773C.794 21.34 0 20.508 0 19.486v-9.282C0 9.18.793 8.35 1.773 8.35h1.05Zm-.942 1.856v9.278h12.236v-9.278H1.882Zm9.412-1.856V4.677c0-1.519-1.44-2.821-3.294-2.821s-3.294 1.3-3.294 2.821V8.35h6.588ZM7.06 13.717c0-.512.418-.927.941-.927.52 0 .941.411.941.927v2.784a.932.932 0 0 1-.94.928.932.932 0 0 1-.942-.928v-2.784Z"
      fill="currentColor"
      fillOpacity={0.24}
    />
  </svg>
)
export default SvgFaLock
