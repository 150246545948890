import React from 'react'
import { Box } from '@chakra-ui/react'
import Weather from './Weather'

const Index = () => (
  <Box>
    <Weather />
  </Box>
)

export default Index
