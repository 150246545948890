import * as React from 'react'

const SvgFaDownload = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.32 1a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 .75.75v7.25h3.25a.75.75 0 0 1 .549 1.262l-7 7.5a.75.75 0 0 1-1.097 0l-7-7.5A.75.75 0 0 1 4.07 8.25h3.25V1Zm1.5.75V9a.75.75 0 0 1-.75.75H5.796l5.274 5.65 5.274-5.65H14.07a.75.75 0 0 1-.75-.75V1.75h-4.5Zm-7.75 14.5a.75.75 0 0 1 .75.75v2.25h18.5V17a.75.75 0 0 1 1.5 0v3a.75.75 0 0 1-.75.75h-20A.75.75 0 0 1 .32 20v-3a.75.75 0 0 1 .75-.75Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaDownload
