import * as React from 'react'

const SvgFaVideo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <circle cx={25} cy={25} r={25} fill="#6891FB" fillOpacity={0.16} />
    <path
      d="M36.156 18.26a1.63 1.63 0 0 0-1.687 0l-2.954 1.758v-2.301c0-.455-.176-.892-.488-1.213A1.641 1.641 0 0 0 29.85 16H14.664a1.64 1.64 0 0 0-1.176.504A1.747 1.747 0 0 0 13 17.717v13.566c0 .455.176.892.488 1.213a1.64 1.64 0 0 0 1.176.504h15.187c.44 0 .864-.182 1.176-.504.312-.322.487-.758.488-1.213v-2.302l2.954 1.76c.522.31 1.165.31 1.687 0 .522-.311.844-.886.844-1.508v-9.466a1.762 1.762 0 0 0-.224-.872 1.696 1.696 0 0 0-.62-.636Zm-6.141 13.023a.169.169 0 0 1-.164.17H14.665a.17.17 0 0 1-.164-.17V17.717a.169.169 0 0 1 .164-.169H29.85c.09.001.163.076.164.17v13.565Zm5.485-2.05c0 .07-.035.133-.094.168a.183.183 0 0 1-.187 0l-3.704-2.206v-5.39L35.22 19.6a.183.183 0 0 1 .187 0 .195.195 0 0 1 .094.168v9.466Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaVideo
