import { put } from 'redux-saga/effects'
import { landingPageSeen } from '../actions'

export default () =>
  // eslint-disable-next-line consistent-return
  function* setLandingPageSeen() {
    try {
      yield put(landingPageSeen.loading())
      yield put(landingPageSeen.success({ landingPageSeen: true }))
    } catch (error) {
      yield put(landingPageSeen.error({ error }))
    }
  }
