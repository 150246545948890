import { request } from '../../http'

export default () =>
  async ({ data }) => {
    const { phoneNumber, code } = data
    return request({
      route: `/auth/register?phoneNumber=${phoneNumber}&code=${code}`,
      method: 'POST',
    })
  }
