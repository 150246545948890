import * as React from 'react'

const SvgFaPhone = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="m19.356 14.23-2.423-2.426c-.478-.496-1.057-.757-1.67-.757-.608 0-1.19.26-1.683.751l-1.262 1.262-.182-.091a4.55 4.55 0 0 1-.413-.224c-1.28-.812-2.447-1.877-3.569-3.251-.495-.625-.837-1.15-1.085-1.668.32-.3.62-.605.906-.898l.368-.37c.505-.505.772-1.094.772-1.7 0-.611-.267-1.2-.772-1.7L7.14 1.954a25.416 25.416 0 0 1-.4-.409l-.007-.006c-.263-.27-.546-.558-.829-.82C5.424.249 4.85 0 4.244 0 3.64 0 3.06.25 2.565.723L1.049 2.24a3.42 3.42 0 0 0-1.03 2.202C-.063 5.46.122 6.535.6 7.82c.72 1.959 1.806 3.77 3.414 5.703a21.05 21.05 0 0 0 6.992 5.476l.002.002c1.04.493 2.433 1.071 3.974 1.171H15c.09.004.181.008.28.008 1.105 0 1.996-.383 2.723-1.17.012-.015.029-.03.045-.053a9.2 9.2 0 0 1 .684-.709l.057-.055c.232-.222.415-.405.578-.574.487-.507.743-1.095.741-1.704 0-.61-.26-1.193-.753-1.686ZM1.698 4.584c.041-.462.214-.83.542-1.158l1.5-1.5c.165-.16.338-.245.5-.245.195 0 .368.134.478.246l.012.012c.228.212.448.436.68.672l.106.108.211.216c.07.07.139.14.208.212l1.207 1.207c.127.126.277.318.277.509s-.15.383-.277.509l-.189.191c-.06.064-.124.127-.187.19l-.086.085c-.34.344-.663.67-1.012.982l-.034.034c-.527.527-.346 1.073-.287 1.252l.012.037.008.02c.33.79.782 1.527 1.466 2.388l.002.003c1.232 1.518 2.53 2.701 3.974 3.62.159.103.32.185.477.262.028.014.059.029.087.045l.002.002c.149.073.291.146.414.224l.036.02.037.02c.179.092.364.139.55.139.313 0 .598-.126.846-.377L14.77 13c.118-.118.297-.257.493-.257.2 0 .372.155.46.248l.004.005 2.44 2.434c.35.35.343.66-.017 1.035l-.004.004c-.171.183-.352.358-.524.523l-.048.047c-.273.265-.554.537-.817.85-.399.428-.853.618-1.472.618-.057 0-.122-.004-.187-.008-1.264-.082-2.455-.58-3.353-1.008a19.304 19.304 0 0 1-6.43-5.037c-1.48-1.782-2.473-3.437-3.13-5.21-.39-1.047-.547-1.894-.486-2.66v.001Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaPhone
