import User from './user'
import invitation from './invitation'
import media from './media'

const middleware = {
  ...User,
  ...invitation,
  ...media,
  default: ({ next, action }) => next(action),
}

export default (state) => (next) => async (action) => {
  // use currentState when you want to display some data from store in toast
  const currentState = state.getState()
  ;(middleware[action.type] || middleware.default)({
    currentState,
    next,
    action,
  })
}
