import styled from 'styled-components'
import { Box } from '@chakra-ui/react'

export const StyledRightWrapper = styled(Box)`
  border-radius: 16px;
  background: white;
  text-align: center;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  min-height: 498px;
  overflow: hidden;
`
