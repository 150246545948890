import { call, put } from 'redux-saga/effects'
import { initiateVerification, onBoardingStep } from '../actions'
import api from '../../services/api'

export default () =>
  // eslint-disable-next-line consistent-return
  function* initiateVerificationPhone({ payload }) {
    try {
      const { phoneNumber, step } = payload
      yield put(initiateVerification.loading())
      yield call(api.auth.initiateVerification(), {
        data: { phoneNumber },
      })
      yield put(initiateVerification.success())
      if (step) {
        yield put(onBoardingStep.process(step))
      }
    } catch (error) {
      yield put(initiateVerification.error({ error }))
    }
  }
