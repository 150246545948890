import api from '../../services/api'
import { sagas } from '../../utils'
import { getEphemeride as actions } from '../actions'

export default () =>
  sagas.createDefaultSaga({
    apiCall: api.weather.getEphemeride,
    actions,
    payloadKey: 'getEphemeride',
  })
