import * as React from 'react'

const SvgMoonLastQuarter = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <circle cx={12} cy={12} r={10} fill="#D7CBA5" />
    <path
      d="M22 12c0 5.523-4.477 10-10 10V2c5.523 0 10 4.477 10 10Z"
      fill="#363537"
      fillOpacity={0.5}
    />
  </svg>
)
export default SvgMoonLastQuarter
