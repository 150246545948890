import { takeEvery } from 'redux-saga/effects'
import * as actions from '../actions'
import sendAudio from './sendAudio'
import sendMedia from './sendMedia'

export default () =>
  function* PaymentSagas() {
    yield takeEvery(actions.sendAudio.process.type, sendAudio())
    yield takeEvery(actions.sendMedia.process.type, sendMedia())
  }
