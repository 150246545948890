import * as React from 'react'

const SvgMeteoMist = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 5A.75.75 0 0 1 9 4.25h4.5a.75.75 0 0 1 0 1.5H9A.75.75 0 0 1 8.25 5Zm-5.5 3a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12A.75.75 0 0 1 2.75 8ZM8 10.25a.75.75 0 0 0 0 1.5h13.5a.75.75 0 0 0 0-1.5H8ZM2.25 14a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75ZM8 16.25a.75.75 0 0 0 0 1.5h11.5a.75.75 0 0 0 0-1.5H8Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMeteoMist
