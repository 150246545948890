import * as React from 'react'

const SvgCircleAvatar = (props) => (
  <svg
    id="CircleAvatar_svg__Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 256 128"
    style={{
      enableBackground: 'new 0 0 256 128',
    }}
    xmlSpace="preserve"
    role="img"
    {...props}
  >
    <style>
      {'.CircleAvatar_svg__st3{fill:#fe8568}.CircleAvatar_svg__st4{fill:#fff}'}
    </style>
    <defs>
      <filter
        id="CircleAvatar_svg__Adobe_OpacityMaskFilter"
        filterUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={256}
        height={128}
      >
        <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
      </filter>
    </defs>
    <mask
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={256}
      height={128}
      id="CircleAvatar_svg__mask0_2540_39212_00000013879064776932936640000012640078689888906165_"
    >
      <path
        d="M-20-38h295c8.8 0 16 7.2 16 16v137c0 8.8-7.2 16-16 16H-20c-8.8 0-16-7.2-16-16V-22c0-8.8 7.2-16 16-16z"
        style={{
          fill: '#fff',
          filter: 'url(#CircleAvatar_svg__Adobe_OpacityMaskFilter)',
        }}
      />
    </mask>
    <g
      style={{
        mask: 'url(#CircleAvatar_svg__mask0_2540_39212_00000013879064776932936640000012640078689888906165_)',
      }}
    >
      <path
        d="M226 0c0 19.6-5.8 37.9-15.8 53.3-1.1 1.6-2.2 3.3-3.4 4.8C189 82.3 160.3 98 128 98c-2.7 0-5.4-.1-8-.3-1.9-.1-3.9-.4-5.8-.6C66.7 90.3 30 49.3 30 0h-2c0 50.5 37.6 92.3 86.2 99 1.8.2 3.5.5 5.3.6 2.8.2 5.6.4 8.5.4 33.1 0 62.5-16.2 80.7-41 1-1.4 2-2.8 3-4.3C222 39 228 20.2 228 0h-2z"
        style={{
          opacity: 0.4,
          fill: '#f4f0e3',
          enableBackground: 'new',
        }}
      />
      <path
        className="CircleAvatar_svg__st3"
        d="M198 0c0 38.6-31.4 70-70 70S58 38.6 58 0H42c0 47.4 38.6 86 86 86s86-38.6 86-86h-16z"
      />
      <path
        className="CircleAvatar_svg__st3"
        d="M240 0c0 61.8-50.2 112-112 112S16 61.8 16 0H0c0 4.4.2 8.8.7 13.1.1 1.1.2 2.1.4 3.2.3 2.1.6 4.3 1 6.4 4.1 22.6 14.1 43.2 28.4 60.1 2 2.3 4 4.5 6.1 6.7l4.1 4.1c2.2 2.1 4.5 4 6.8 5.9 16.4 13.3 36.2 22.7 57.9 26.6 2.1.4 4.2.7 6.4 1 1.1.1 2.1.3 3.2.4 4.3.4 8.7.7 13.1.7s8.8-.2 13.1-.7c1.1-.1 2.1-.2 3.2-.4 2.1-.3 4.3-.6 6.4-1 25.8-4.6 48.9-17 66.8-34.5 1.2-1.2 2.4-2.4 3.5-3.6 3.2-3.4 6.2-6.9 9-10.6 1-1.3 2-2.7 2.9-4 10.4-14.9 17.7-32 21.1-50.6.4-2.1.7-4.2 1-6.4.1-1.1.3-2.1.4-3.2.3-4.4.5-8.8.5-13.2h-16z"
      />
      <path
        className="CircleAvatar_svg__st3"
        d="M240 0c0 61.8-50.2 112-112 112S16 61.8 16 0H0c0 4.4.2 8.8.7 13.1.1 1.1.2 2.1.4 3.2.3 2.1.6 4.3 1 6.4 4.1 22.6 14.1 43.2 28.4 60.1 2 2.3 4 4.5 6.1 6.7l4.1 4.1c2.2 2.1 4.5 4 6.8 5.9 16.4 13.3 36.2 22.7 57.9 26.6 2.1.4 4.2.7 6.4 1 1.1.1 2.1.3 3.2.4 4.3.4 8.7.7 13.1.7s8.8-.2 13.1-.7c1.1-.1 2.1-.2 3.2-.4 2.1-.3 4.3-.6 6.4-1 25.8-4.6 48.9-17 66.8-34.5 1.2-1.2 2.4-2.4 3.5-3.6 3.2-3.4 6.2-6.9 9-10.6 1-1.3 2-2.7 2.9-4 10.4-14.9 17.7-32 21.1-50.6.4-2.1.7-4.2 1-6.4.1-1.1.3-2.1.4-3.2.3-4.4.5-8.8.5-13.2h-16z"
      />
    </g>
    <circle className="CircleAvatar_svg__st4" cx={226} cy={83} r={12} />
    <circle className="CircleAvatar_svg__st4" cx={38} cy={92} r={12} />
    <circle className="CircleAvatar_svg__st3" cx={117} cy={98} r={3} />
    <circle className="CircleAvatar_svg__st3" cx={38} cy={92} r={3} />
    <circle className="CircleAvatar_svg__st3" cx={140} cy={27} r={7} />
    <circle className="CircleAvatar_svg__st3" cx={226} cy={83} r={7} />
    <circle className="CircleAvatar_svg__st3" cx={209} cy={56} r={3} />
  </svg>
)
export default SvgCircleAvatar
