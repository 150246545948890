import * as React from 'react'

const SvgMeteoShowerRain = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.953 4.217c.665.631 1.15 1.45 1.368 2.372a3.004 3.004 0 0 1 3.721 2.691c.93.204 1.72.783 2.202 1.57h1.31a1.465 1.465 0 0 0 0-2.93h-.037a.75.75 0 0 1-.735-.9 1.096 1.096 0 0 0-1.732-1.097.75.75 0 0 1-1.2-.6 2.573 2.573 0 0 0-4.897-1.106Zm7.784 8.133h.817a2.965 2.965 0 0 0 .734-5.84 2.596 2.596 0 0 0-3.08-2.258 4.075 4.075 0 0 0-7.502-.89 4.8 4.8 0 0 0-6.85 4.102A4.351 4.351 0 0 0 4.6 16.1h1.38l-.237.64a.75.75 0 1 0 1.408.52l.427-1.16H16.3a3.45 3.45 0 0 0 3.437-3.75Zm-17.987-.6A2.85 2.85 0 0 1 4.6 8.9h.022a.75.75 0 0 0 .748-.832 3.3 3.3 0 1 1 6.58-.368.75.75 0 0 0 1.2.6A1.5 1.5 0 0 1 15.52 9.8a.75.75 0 0 0 .735.899h.045a1.95 1.95 0 1 1 0 3.9H4.6a2.85 2.85 0 0 1-2.85-2.85Zm8.51 4.546a.75.75 0 0 1 .444.963l-.553 1.5a.75.75 0 1 1-1.408-.518l.553-1.5a.75.75 0 0 1 .963-.445ZM9.256 20.76a.75.75 0 0 0-1.408-.518l-.553 1.5a.75.75 0 1 0 1.408.518l.553-1.5Zm3.055-.963a.75.75 0 0 1 .445.963l-.553 1.5a.75.75 0 1 1-1.408-.518l.553-1.5a.75.75 0 0 1 .963-.445Zm1.892-2.537a.75.75 0 1 0-1.408-.518l-.553 1.5a.75.75 0 1 0 1.408.518l.553-1.5Zm-7.947 2a.75.75 0 1 0-1.408-.518l-.553 1.5a.75.75 0 0 0 1.408.518l.553-1.5Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMeteoShowerRain
