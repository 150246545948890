import { createReducer } from '@reduxjs/toolkit'
import _ from 'lodash'
import { reset as resetAction } from '../actions'

import sendAudio from './sendAudio'
import sendMedia from './sendMedia'

import { reducers as reducerHelpers } from '../../utils'

const INITIAL_STATE = _.merge(
  {},
  sendAudio.initialState,
  sendMedia.initialState
)

const actionsMap = _.merge({}, sendAudio.actionsMap, sendMedia.actionsMap, {
  [resetAction]: (state, action) =>
    reducerHelpers.resetState(state, INITIAL_STATE, action.payload.keys),
})

export default createReducer(INITIAL_STATE, actionsMap)
