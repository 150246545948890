import { takeEvery } from 'redux-saga/effects'
import * as actions from '../actions'
import get from './get'
import getEphemeride from './getEphemeride'

export default () =>
  function* PaymentSagas() {
    yield takeEvery(actions.get.process.type, get())
    yield takeEvery(actions.getEphemeride.process.type, getEphemeride())
  }
