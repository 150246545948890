import * as React from 'react'

const SvgMeteoThunderstorm = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.953 5.217c.665.631 1.15 1.45 1.368 2.372a3.004 3.004 0 0 1 3.721 2.691c.93.204 1.72.783 2.202 1.57h1.31a1.465 1.465 0 0 0 0-2.93h-.037a.75.75 0 0 1-.735-.9 1.096 1.096 0 0 0-1.732-1.097.75.75 0 0 1-1.2-.6 2.573 2.573 0 0 0-4.897-1.106Zm7.784 8.133h.817a2.965 2.965 0 0 0 .734-5.84 2.596 2.596 0 0 0-3.08-2.258 4.075 4.075 0 0 0-7.502-.89 4.8 4.8 0 0 0-6.85 4.102A4.351 4.351 0 0 0 4.6 17.1h11.7a3.45 3.45 0 0 0 3.437-3.75Zm-17.987-.6A2.85 2.85 0 0 1 4.6 9.9h.022a.75.75 0 0 0 .748-.832 3.3 3.3 0 1 1 6.58-.368.75.75 0 0 0 1.2.6 1.5 1.5 0 0 1 2.37 1.501.75.75 0 0 0 .735.899h.045a1.95 1.95 0 1 1 0 3.9H4.6a2.85 2.85 0 0 1-2.85-2.85Z"
      fill="currentColor"
    />
    <path
      d="M10.913 12H9.178a.5.5 0 0 0-.398.198l-3.172 4.188a.5.5 0 0 0 .398.802h.416a.5.5 0 0 1 .467.678L5.307 22l5.757-5.643a.5.5 0 0 0-.35-.857h-.627a.5.5 0 0 1-.38-.825l1.585-1.85a.5.5 0 0 0-.38-.825Z"
      fill="#FE8568"
    />
  </svg>
)
export default SvgMeteoThunderstorm
