import * as React from 'react'

const SvgLogoIndex = (props) => (
  <svg
    viewBox="0 0 91 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <g clipPath="url(#logo-index_svg__a)">
      <path
        d="M60.837 17.894a3.588 3.588 0 0 1-3.597-3.584c0-2.011 1.637-3.585 3.597-3.585.705 0 1.295-.588 1.295-1.29 0-.702-.59-1.29-1.295-1.29-2.017 0-3.769.968-4.892 2.428-1.123-1.46-2.931-2.428-4.892-2.428-.704 0-1.294.588-1.294 1.29 0 .702.59 1.29 1.294 1.29a3.588 3.588 0 0 1 3.598 3.585c0 2.01-1.637 3.584-3.598 3.584-.704 0-1.294.588-1.294 1.29 0 .702.59 1.29 1.294 1.29 2.018 0 3.77-.968 4.892-2.428 1.123 1.46 2.932 2.428 4.892 2.428.705 0 1.295-.588 1.295-1.29 0-.74-.59-1.29-1.295-1.29ZM27.235 18.482a3.795 3.795 0 0 1-3.788-3.793c0-2.087 1.713-3.775 3.788-3.775 2.094 0 3.807 1.688 3.807 3.775a3.8 3.8 0 0 1-3.807 3.793ZM32.413 2c-.762 0-1.37.607-1.37 1.366v6.031a6.545 6.545 0 0 0-3.808-1.214c-3.598 0-6.529 2.921-6.529 6.506 0 3.584 2.931 6.524 6.53 6.524 3.597 0 6.547-2.92 6.547-6.524V3.366c0-.76-.628-1.366-1.37-1.366ZM1.828 7.937c-.762 0-1.371.607-1.371 1.365v10.3c0 .758.61 1.365 1.37 1.365.762 0 1.371-.607 1.371-1.366V9.302c0-.758-.609-1.365-1.37-1.365ZM42.39 10.649c1.98.038 2.171 1.27 2.19 1.422.019.247-.019.418-.19.607-.343.38-1.58 1.233-5.768 1.366.21-1.916 1.827-3.395 3.769-3.395Zm3.313 6.354c-.99.796-2.094 1.195-3.293 1.195a3.783 3.783 0 0 1-3.008-1.48c3.541-.19 5.844-.93 7.024-2.238.686-.759.99-1.707.876-2.731-.324-3.035-3.35-3.85-4.911-3.85-3.598 0-6.548 2.92-6.548 6.524s2.931 6.525 6.548 6.525c1.808 0 3.54-.626 5.025-1.821.59-.474.686-1.328.21-1.916a1.367 1.367 0 0 0-1.923-.208Z"
        fill="currentColor"
      />
      <path
        d="M75 17.989c-.21 0-.4.038-.552.114a1.28 1.28 0 0 1-.533.113c-.171 0-.343-.037-.495-.094a.74.74 0 0 1-.362-.323c-.095-.151-.133-.322-.133-.55v-6.714h1.732c.381 0 .705-.133.971-.38.267-.246.38-.568.38-.929 0-.379-.132-.701-.38-.967-.266-.266-.59-.38-.97-.38h-1.752V5.34c0-.418-.133-.779-.4-1.044-.266-.285-.609-.417-1.028-.417-.418 0-.78.132-1.046.417-.286.284-.42.626-.42 1.043v2.523H68.89c-.381 0-.705.132-.971.379-.267.247-.38.569-.38.93 0 .379.132.701.38.967.266.265.59.379.97.379h1.124v6.847c0 .797.114 1.404.361 1.859.248.455.552.777.914 1.005.362.209.761.36 1.161.417.419.057.78.076 1.123.076.571 0 1.104-.152 1.6-.436.494-.285.742-.664.742-1.12 0-.378-.096-.663-.286-.872-.19-.19-.4-.303-.628-.303ZM79.243 7.785c.305 0 .59.076.857.227.266.152.457.38.59.683l3.578 8.137-.533.246 3.636-8.345c.267-.645.705-.948 1.295-.91.4 0 .723.132.99.38a1.286 1.286 0 0 1 .323 1.289 2.013 2.013 0 0 1-.133.36l-4.569 9.958c-.266.588-.666.891-1.237.91-.305.057-.61 0-.895-.17-.285-.152-.495-.418-.647-.76l-4.511-9.976a2.027 2.027 0 0 1-.114-.322 1.657 1.657 0 0 1-.058-.455c0-.285.134-.57.381-.854.267-.246.61-.398 1.047-.398Z"
        fill="currentColor"
      />
      <path
        d="M12.183 7.937h-.19.19c-1.123 0-2.17.284-3.103.777-.019 0-.019.02-.038.02-.152.094-.323.189-.476.284-.019.019-.057.038-.076.057a6.481 6.481 0 0 0-2.855 5.33v5.216c0 .758.609 1.365 1.37 1.365.762 0 1.37-.607 1.37-1.366v-5.159c0-2.086 1.714-3.774 3.789-3.774s3.788 1.688 3.788 3.774v5.16c0 .758.61 1.365 1.37 1.365.762 0 1.371-.607 1.371-1.366v-5.159c.038-3.604-2.912-6.524-6.51-6.524ZM1.827 2.303C.837 2.303 0 3.138 0 4.163c0 1.023.838 1.82 1.827 1.82a1.824 1.824 0 0 0 1.828-1.82c0-.987-.8-1.86-1.828-1.86Z"
        fill="currentColor"
      />
      <path
        d="M66.414 17.23c-.99 0-1.827.835-1.827 1.878s.837 1.82 1.827 1.82a1.824 1.824 0 0 0 1.828-1.82c0-.986-.8-1.878-1.828-1.878Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="logo-index_svg__a">
        <path fill="#fff" transform="translate(.006)" d="M0 0h90v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgLogoIndex
