import api from '../../services/api'
import { sagas } from '../../utils'
import { bills as actions } from '../actions'

export default () =>
  sagas.createDefaultSaga({
    apiCall: api.payment.bills,
    actions,
    payloadKey: 'bills',
  })
