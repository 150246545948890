import { request } from '../../http'

export default () =>
  async ({ data }) => {
    const { phoneNumber } = data
    return request({
      route: `/auth/isUserRegistered?phoneNumber=${phoneNumber}`,
      method: 'GET',
    })
  }
