import * as React from 'react'

const SvgFaCross = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.53.47A.75.75 0 0 0 .47 1.53L5.94 7 .47 12.47a.75.75 0 1 0 1.06 1.06L7 8.06l5.47 5.47a.75.75 0 1 0 1.06-1.06L8.06 7l5.47-5.47A.75.75 0 0 0 12.47.47L7 5.94 1.53.47Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.293.293a1 1 0 0 1 1.414 0L7 5.586 12.293.293a1 1 0 1 1 1.414 1.414L8.414 7l5.293 5.293a1 1 0 0 1-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L5.586 7 .293 1.707a1 1 0 0 1 0-1.414ZM5.939 7 .47 12.47a.75.75 0 1 0 1.061 1.06L7 8.06l5.47 5.47a.75.75 0 1 0 1.06-1.06L8.06 7l5.47-5.47A.75.75 0 0 0 12.47.47L7 5.94 1.53.47A.75.75 0 0 0 .47 1.53L5.94 7Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaCross
