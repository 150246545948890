import axios from 'axios'
import transform from './transform'
import HTTPError from './HTTPError'
import { getConfig } from '../../config'

export { default as dataTypes } from './dataTypes'

export const request = async ({
  route,
  method,
  headers,
  data,
  dataType,
  params,
  withCredentials = false,
  responseType = 'json',
}) => {
  try {
    const transformRequest = [transform(dataType)]
    const response = await axios.request({
      baseURL: getConfig().baseURL,
      url: route,
      method,
      headers,
      data,
      transformRequest,
      params,
      withCredentials,
      responseType,
    })
    return response.data
  } catch (err) {
    if (err.response) {
      throw new HTTPError(err.response.status, err.response.data)
    } else if (err.request) {
      throw new Error(
        'Something wrong (spotty network, cross-domain request not authorized, etc.)'
      )
    } else {
      throw err
    }
  }
}
