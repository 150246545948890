import * as React from 'react'

const SvgFaStop = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <g clipPath="url(#fa-stop_svg__a)">
      <path
        d="M15.014 17.392H2.7c-.955 0-1.733-.758-1.733-1.688V3.405c0-.93.778-1.687 1.733-1.687H15c.954 0 1.733.758 1.733 1.687v12.3c0 .943-.779 1.687-1.719 1.687ZM2.7 3.176a.254.254 0 0 0-.25.243v12.299c0 .129.118.243.25.243H15c.132 0 .25-.114.25-.243V3.405A.254.254 0 0 0 15 3.16H2.7v.015Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="fa-stop_svg__a">
        <path
          fill="#fff"
          transform="translate(.216 .216)"
          d="M0 0h18.018v18.018H0z"
        />
      </clipPath>
    </defs>
  </svg>
)
export default SvgFaStop
