import * as React from 'react'

const SvgMeteoSnow = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.25a.75.75 0 0 1 .75.75v2.19l1.72-1.72a.75.75 0 1 1 1.06 1.06l-2.78 2.78V9a.752.752 0 0 1-.006.093c.5.128.951.381 1.315.725a.766.766 0 0 1 .053-.033l1.461-.847 1.012-3.8a.75.75 0 1 1 1.45.387l-.626 2.35 1.894-1.098a.75.75 0 1 1 .752 1.298l-1.894 1.098 2.35.625a.75.75 0 0 1-.386 1.45l-3.8-1.012-1.461.847-.006.003a2.998 2.998 0 0 1 .057 1.624l1.404.82 3.803-.997a.75.75 0 1 1 .381 1.45l-2.352.618 1.89 1.104a.75.75 0 1 1-.757 1.296l-1.89-1.105.617 2.352a.75.75 0 1 1-1.451.38l-.998-3.802-1.356-.793a2.998 2.998 0 0 1-1.456.873v1.533l2.78 2.78a.75.75 0 1 1-1.06 1.061l-1.72-1.72v2.19a.75.75 0 0 1-1.5 0v-2.19l-1.72 1.72a.75.75 0 0 1-1.06-1.06l2.78-2.78v-1.535a2.999 2.999 0 0 1-1.464-.88l-1.36.787-1.011 3.8a.75.75 0 0 1-1.45-.386l.626-2.35-1.894 1.097a.75.75 0 1 1-.752-1.298l1.894-1.098-2.35-.625a.75.75 0 0 1 .386-1.45l3.8 1.012 1.407-.815a3.008 3.008 0 0 1 .063-1.624l-.006-.003-1.458-.852-3.803.997a.75.75 0 1 1-.381-1.45l2.352-.618-1.89-1.105a.75.75 0 0 1 .757-1.295l1.89 1.105-.617-2.352a.75.75 0 0 1 1.451-.38l.998 3.802 1.458.853c.018.01.036.022.053.034.363-.34.81-.591 1.307-.718A.752.752 0 0 1 11.25 9V7.31L8.47 4.53a.75.75 0 1 1 1.06-1.06l1.72 1.72V3a.75.75 0 0 1 .75-.75Zm0 11.25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMeteoSnow
