import { put, call } from 'redux-saga/effects'
import createDefaultHeaders from '../../utils/sagas/createDefaultHeaders'
import { getAllByStatus } from '../actions'
import api from '../../services/api'

const contactStatus = {
  VALIDATED: 'validated',
  INVITED: 'invited',
  INCOMING: 'incoming_invited',
}

export default () =>
  // eslint-disable-next-line consistent-return
  function* currentContactSaga() {
    try {
      yield put(getAllByStatus.loading())
      const validated = yield call(api.contact.get(), {
        data: { state: contactStatus.VALIDATED },
        headers: yield createDefaultHeaders(true),
      })
      const invited = yield call(api.contact.get(), {
        data: { state: contactStatus.INVITED },
        headers: yield createDefaultHeaders(true),
      })
      const incoming = yield call(api.contact.get(), {
        data: { state: contactStatus.INCOMING },
        headers: yield createDefaultHeaders(true),
      })

      yield put(getAllByStatus.success({ validated, invited, incoming }))
    } catch (error) {
      yield put(getAllByStatus.error({ error }))
    }
  }
