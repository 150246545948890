import styled from 'styled-components'
import { StyledLeftWrapper as AccountLeftWrapper } from '../../Account/Sidebar'

export const StyledLeftWrapper = styled(AccountLeftWrapper)`
  .link {
    &:hover {
      background: rgba(255, 255, 255, 0.8);
      text-decoration: none;
      border-color: transparent;
      border-radius: 16px;
    }
  }
  .sidebar__thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    font-size: 20px;
    font-weight: 900;
  }
`
