import { isArray, set, get } from 'lodash'

export default (previousState, initialState, keys = []) => {
  const newState = { ...previousState }
  if (!isArray(keys) || keys.length === 0) {
    return initialState
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    set(newState, key, get(initialState, key))
  }

  return newState
}
