import * as React from 'react'

const SvgFaHeartIllu = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="m39.347 44.531-14.205-9.864-14.204 9.864V9.414c0-1.046.427-2.05 1.188-2.79a4.118 4.118 0 0 1 2.87-1.155h20.292c1.077 0 2.109.415 2.87 1.155a3.891 3.891 0 0 1 1.189 2.79v35.117Z"
      fill="#F4F0E3"
    />
    <path
      d="m10.938 44.531 14.204-9.864V5.469H14.996a4.118 4.118 0 0 0-2.87 1.155 3.891 3.891 0 0 0-1.188 2.79v35.117Z"
      fill="#EDE5C9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.313 13.281a4.688 4.688 0 0 0-4.685 4.513h-.003a7.926 7.926 0 0 0 0 .175c0 .215.015.427.043.635.561 5.31 6.513 8.937 9.332 10.127V17.794h-.003a4.688 4.688 0 0 0-4.684-4.513ZM25 17.97V28.73c2.82-1.19 8.773-4.819 9.333-10.13a4.739 4.739 0 0 0 .041-.707l.001-.1h-.003a4.688 4.688 0 0 0-9.369 0H25v.175Z"
      fill="#FE8568"
    />
  </svg>
)
export default SvgFaHeartIllu
