import * as React from 'react'

const SvgMoonFullMoon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <circle cx={12} cy={12} r={10} fill="#D7CBA5" />
  </svg>
)
export default SvgMoonFullMoon
