import {
  MoonFirstQuarter,
  MoonFullMoon,
  MoonLastQuarter,
  MoonNewMoon,
  MoonWaningCrescent,
  MoonWaningGibous,
  MoonWaxingCrescent,
  MoonWaxingGibous,
} from '../../../../components/Svg'

export const getMoonIcon = (moon) => {
  switch (moon) {
    case 'new-moon':
      return <MoonNewMoon />
    case 'waxing-crescent':
      return <MoonWaxingCrescent />
    case 'first-quarter':
      return <MoonFirstQuarter />
    case 'waxing-gibous':
      return <MoonWaxingGibous />
    case 'full-moon':
      return <MoonFullMoon />
    case 'waning-gibous':
      return <MoonWaningGibous />
    case 'last-quarter':
      return <MoonLastQuarter />
    case 'waning-crescent':
      return <MoonWaningCrescent />
    default:
      return ''
  }
}
