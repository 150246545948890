import React, { useState, useEffect } from 'react'
import { Box } from '@chakra-ui/react'

const Index = () => {
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 60000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Box as="h3" textStyle="h3" color="white">
      {date.toLocaleDateString('fr-fr', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })}
    </Box>
  )
}

export default Index
