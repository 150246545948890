import * as React from 'react'
import { Box } from '@chakra-ui/react'

import styled from 'styled-components'
import { FaChecked } from '../../Svg'

const CustomRadio = styled('input')`
  -webkit-appearance: none !important;
`

export const InputRadio = ({
  input,
  meta,
  onChange,
  invalid,
  isFacultatif,
  maxLength,
  radios,
  ...rest
}) => (
  <>
    <label htmlFor={input.id} style={{ cursor: 'pointer' }}>
      <Box display="flex" alignItems="center">
        <Box
          h="32px"
          w="32px"
          mr={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={32}
          border="1px solid #F4F0E3"
          color={input.checked ? 'white' : 'primary.400'}
          bg={input.checked ? 'primary.400' : 'white'}
        >
          {input.checked && <FaChecked />}
        </Box>
        <CustomRadio {...input} {...rest} />
        {rest.label}
      </Box>
    </label>

    {(meta.error || meta.submitError) && meta.touched && (
      <Box as="p" fontWeight="700" color="red.400" textAlign="left">
        {meta.error || meta.submitError}
      </Box>
    )}
  </>
)
