import styled from 'styled-components'
import { Box } from '@chakra-ui/react'

export const NavbarWrapper = styled(Box)`
  &.is__active {
    background: #2e2623;
  }
`

export const StyledNavbar = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 67px 80px;
  .button__active {
    background: chocolate;
  }
`
