import qs from 'qs'
import dataTypes from './dataTypes'

const transform = {
  [dataTypes.FORM_DATA](data, headers) {
    const formData = new FormData()
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }

    headers['Content-Type'] = 'multipart/form-data'
    return formData
  },
  [dataTypes.URL_ENCODED](data, headers) {
    headers['Content-Type'] = 'application/x-www-form-urlencoded'
    return qs.stringify(data)
  },
  default(data, headers) {
    headers['Content-Type'] = 'application/json'
    return JSON.stringify(data)
  },
}

export default (type) => transform[type] || transform.default
