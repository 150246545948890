import React from 'react'
import { Button } from '@chakra-ui/react'
import { FaBack } from '../Svg'

export const BackButton = ({ callback }) => (
  <Button
    position="absolute"
    left={6}
    top={6}
    height={50}
    width={50}
    bg="white"
    color="primary.400"
    _hover={{ bg: '#ddd' }}
    fontSize={20}
    p={0}
    onClick={() => callback()}
  >
    <FaBack />
  </Button>
)
