import { paths } from './paths'
import LandingPage from '../../views/LandingPage'
import PublicRoute from '../PublicRoute'

const routes = [
  {
    path: paths.LANDING_PAGE,
    element: <PublicRoute />,
    children: [
      {
        path: paths.LANDING_PAGE,
        element: <LandingPage />,
      },
    ],
  },
]

export default routes
