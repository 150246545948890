import React from 'react'
import { Box } from '@chakra-ui/react'
import styled from 'styled-components'
import { useOutletContext } from 'react-router-dom'
import { FaPhone } from '../../../../components/Svg'

export const CallWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 30px;
  height: 100%;
  h2 {
    line-height: 40px;
  }
  .call__icon {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    color: white;
    box-shadow: 2px 4px 0 rgba(0, 0, 0, 0.08);
    font-size: 20px;
  }
  .audio__wrapper {
    svg {
      circle {
        display: none;
      }
    }
  }
`

const Index = () => {
  const currentContact = useOutletContext()
  return (
    <CallWrapper>
      <Box as="h2" textStyle="h2">
        Appelez {currentContact.firstName} en visio
      </Box>
      <Box as="p" textStyle="p">
        Appuyez sur le téléphone vert.
      </Box>
      <Box className="call__icon" bg="green.500" fontSize={50}>
        <FaPhone />
      </Box>
    </CallWrapper>
  )
}

export default Index
