import { v4 as uuidv4 } from 'uuid'

import { errors } from '../../actions'
import { toastStatus } from '../../helpers'
import { user } from '../../../actions'

export default {
  [user.update.error.type]: ({ next, action }) => {
    next(
      errors({
        id: uuidv4(),
        status: toastStatus.ERROR,
        title:
          "Une erreur s'est produite lors de la modification de votre profil !",
        description: '',
      })
    )
    next(action)
  },
}
