import * as React from 'react'

const SvgMeteoScatteredClouds = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.068 9.84C5.208 7.145 7.576 5 10.477 5c2.56 0 4.704 1.67 5.27 3.913.263-.062.539-.095.822-.095 1.786 0 3.249 1.3 3.376 2.949C21.692 12.127 23 13.59 23 15.34 23 17.36 21.257 19 19.108 19h-13.2C3.198 19 1 16.934 1 14.386c0-2.278 1.758-4.171 4.068-4.546Zm.84 1.524c-1.776 0-3.216 1.353-3.216 3.022 0 1.67 1.44 3.023 3.216 3.023h13.2c1.215 0 2.2-.926 2.2-2.068s-.985-2.068-2.2-2.068h-.051a.868.868 0 0 1-.655-.292.76.76 0 0 1-.174-.662 1.51 1.51 0 0 0 .034-.319c0-.879-.758-1.59-1.693-1.59-.382 0-.732.117-1.015.317a.891.891 0 0 1-.886.075.792.792 0 0 1-.468-.711c0-1.933-1.667-3.5-3.723-3.5s-3.723 1.567-3.723 3.5c0 .132.008.262.023.39a.765.765 0 0 1-.212.619.871.871 0 0 1-.632.264h-.025Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMeteoScatteredClouds
