import api from '../../services/api'
import { sagas } from '../../utils'
import { send as actions } from '../actions'

export default () =>
  sagas.createDefaultSaga({
    apiCall: api.invitation.send,
    actions,
    payloadKey: 'invitation',
  })
