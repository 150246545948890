import { select, call, put } from 'redux-saga/effects'
import _ from 'lodash'
import { getUserToken, onBoardingStep } from '../actions'
import api from '../../services/api'
import { onBoardingStepper } from '../../../helpers/onBoardingStepper'

export default () =>
  // eslint-disable-next-line consistent-return
  function* getUserTokenSaga({ payload }) {
    try {
      const { code } = payload
      const state = yield select()
      yield put(getUserToken.loading())
      const { phoneNumber } = state.session.onBoarding

      if (_.isNull(state.session.onBoarding.pendingRegistration)) {
        const { user, accessToken } = yield call(api.auth.register(), {
          data: { phoneNumber, code },
        })
        yield put(getUserToken.success({ user, accessToken }))
        return yield put(
          onBoardingStep.process(onBoardingStepper.subscriptionInformations)
        )
      }

      const { user, accessToken } = yield call(api.auth.getUserToken(), {
        data: { phoneNumber, code },
      })

      yield put(getUserToken.success({ user, accessToken }))

      if (user.status !== 'active') {
        return yield put(
          onBoardingStep.process(onBoardingStepper.subscriptionInformations)
        )
      }
      return yield put(onBoardingStep.process(onBoardingStepper.connected))
    } catch (error) {
      yield put(getUserToken.error({ error }))
    }
  }
