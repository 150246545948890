import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './Sidebar'
import { StyledAccountWrapper } from '../../styles/Account'
import { StyledLeftWrapper } from '../../styles/Account/Sidebar'
import { StyledRightWrapper } from '../../styles/Account/UserInformations'
import { StyledSection } from '../../styles/layout'

const Index = () => (
  <StyledSection>
    <StyledAccountWrapper>
      <StyledLeftWrapper>
        <Sidebar />
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <Outlet />
      </StyledRightWrapper>
    </StyledAccountWrapper>
  </StyledSection>
)

export default Index
