import * as React from 'react'

const SvgMeteoRain = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.714 8c0 1.434-.64 2.72-1.651 3.584a3.452 3.452 0 0 0-2.02-1.304 3 3 0 0 0-3.722-2.69 4.792 4.792 0 0 0-1.226-2.232A4.714 4.714 0 0 1 21.715 8Zm-9.631-3.439a6 6 0 1 1 8.555 8.211A3.452 3.452 0 0 1 17.3 17.1H8.578l-.427 1.16a.75.75 0 1 1-1.408-.52l.237-.64H5.6a4.35 4.35 0 0 1-.744-8.637 4.8 4.8 0 0 1 7.227-3.902ZM2.75 12.75A2.85 2.85 0 0 1 5.6 9.9h.022a.75.75 0 0 0 .748-.832 3.3 3.3 0 1 1 6.58-.368.75.75 0 0 0 1.2.6 1.5 1.5 0 0 1 2.37 1.501.75.75 0 0 0 .735.899h.045a1.95 1.95 0 1 1 0 3.9H5.6a2.85 2.85 0 0 1-2.85-2.85Zm8.51 4.546a.75.75 0 0 1 .444.963l-.553 1.5a.75.75 0 1 1-1.408-.518l.553-1.5a.75.75 0 0 1 .963-.445Zm-1.003 4.463a.75.75 0 0 0-1.408-.518l-.553 1.5a.75.75 0 1 0 1.408.518l.553-1.5Zm3.055-.963a.75.75 0 0 1 .445.963l-.553 1.5a.75.75 0 1 1-1.408-.518l.553-1.5a.75.75 0 0 1 .963-.445Zm1.892-2.537a.75.75 0 1 0-1.408-.518l-.553 1.5a.75.75 0 1 0 1.408.518l.553-1.5Zm-7.947 2a.75.75 0 1 0-1.408-.518l-.553 1.5a.75.75 0 0 0 1.408.518l.553-1.5Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMeteoRain
