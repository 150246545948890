import * as React from 'react'

const SvgIllustrationCarteBancaire = (props) => (
  <svg
    viewBox="0 0 340 214"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <g clipPath="url(#illustration-carte-bancaire_svg__a)">
      <rect width={340} height={214} rx={16} fill="#F4F0E3" />
      <g opacity={0.6} fill="#FE8568">
        <path
          opacity={0.7}
          d="M215.035 98.677C138.982 98.677 108.263 45.153 0 67.28v-168.047h340V21.531c-29.825 62.913-95.737 77.645-124.965 77.146Z"
        />
        <path
          opacity={0.6}
          d="M188.524 54.423c-68.12 0-102.18-70.27-188.524-31.397v-123.793h340v46.647C317.891-11.361 256.643 54.423 188.524 54.423Z"
        />
        <path d="M187.823 43.06C119.957 43.06 86.023-49.336 0-10.464v-90.303h269.382C280.693-10.464 255.69 43.06 187.823 43.06Z" />
      </g>
      <path
        d="m33.507 133.427 5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6Zm14.577 0 5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6Zm14.577 0 5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6Zm14.576 0 5.025-7.12-4.225-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.017 7.12h-2.6l-3.713-5.296-3.712 5.296h-2.6ZM103.995 133.427l5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6Zm14.577 0 5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6Zm14.577 0 5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6Zm14.577 0 5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6ZM174.482 133.427l5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6Zm14.577 0 5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6Zm14.577 0 5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6Zm14.577 0 5.024-7.12-4.224-6.08h2.512l3 4.336 3-4.336h2.512l-4.216 6.08 5.016 7.12h-2.6l-3.712-5.296-3.712 5.296h-2.6Z"
        fill="#363537"
        fillOpacity={0.4}
      />
      <path
        d="M246.979 133.427v-11.144h-1.704v-2.056h3.8v13.2h-2.096Zm5.913 0v-1.048a97.66 97.66 0 0 0 2.888-2.312 38.584 38.584 0 0 0 2.08-1.84 13.86 13.86 0 0 0 1.36-1.48c.347-.442.597-.85.752-1.224.155-.378.232-.741.232-1.088a2.544 2.544 0 0 0-1.248-2.216 2.44 2.44 0 0 0-1.288-.344c-.464 0-.888.115-1.272.344a3.04 3.04 0 0 0-.992.992c-.272.432-.469.947-.592 1.544l-1.792-.784a5.592 5.592 0 0 1 .616-1.632c.283-.49.632-.912 1.048-1.264a4.51 4.51 0 0 1 1.408-.808 4.883 4.883 0 0 1 1.696-.288c.896 0 1.696.192 2.4.576a4.275 4.275 0 0 1 1.672 1.568c.405.662.608 1.414.608 2.256 0 .427-.061.846-.184 1.256a4.914 4.914 0 0 1-.584 1.264 8.539 8.539 0 0 1-1.064 1.352c-.437.47-.973.976-1.608 1.52a44.433 44.433 0 0 1-2.224 1.768h5.904v1.888h-9.816Zm17.337.256c-.784 0-1.498-.138-2.144-.416a4.362 4.362 0 0 1-1.6-1.16 3.99 3.99 0 0 1-.848-1.76l1.968-.872c.022.47.144.883.368 1.24.224.352.531.63.92.832.39.198.838.296 1.344.296.715 0 1.286-.197 1.712-.592.432-.4.648-.925.648-1.576 0-.458-.106-.872-.32-1.24a2.36 2.36 0 0 0-.84-.872 2.228 2.228 0 0 0-1.176-.32c-.218 0-.402.027-.552.08v-1.432h.544c.406 0 .771-.098 1.096-.296.331-.202.592-.469.784-.8.192-.33.288-.688.288-1.072 0-.378-.096-.72-.288-1.024a1.972 1.972 0 0 0-.768-.728 2.122 2.122 0 0 0-1.064-.272c-.394 0-.76.094-1.096.28-.336.182-.61.432-.824.752a2.262 2.262 0 0 0-.376 1.072l-1.688-.736a3.627 3.627 0 0 1 .736-1.608 3.852 3.852 0 0 1 1.416-1.08 4.43 4.43 0 0 1 1.864-.392c.768 0 1.454.163 2.056.488a3.726 3.726 0 0 1 1.432 1.32 3.45 3.45 0 0 1 .528 1.888c0 .406-.066.787-.2 1.144a2.99 2.99 0 0 1-.56.952c-.24.283-.525.515-.856.696.395.176.739.419 1.032.728.299.304.531.664.696 1.08.171.411.256.87.256 1.376 0 .768-.186 1.456-.56 2.064a3.938 3.938 0 0 1-1.568 1.44c-.672.347-1.458.52-2.36.52Zm13.71-.256v-2.464h-6.592v-.712l6.488-10.024h2.08v8.896h1.536v1.84h-1.536v2.464h-1.976Zm-3.776-4.304h3.776v-6.064l-3.776 6.064Z"
        fill="#363537"
      />
      <g filter="url(#illustration-carte-bancaire_svg__b)">
        <path
          d="M33.17 79.656c0-3.502 2.833-6.341 6.326-6.341h31.628c3.494 0 6.326 2.839 6.326 6.34v19.023c0 3.502-2.832 6.341-6.326 6.341H39.497c-3.494 0-6.326-2.839-6.326-6.341V79.656Z"
          fill="#FE8568"
          fillOpacity={0.4}
        />
        <path
          d="M39.496 74.315h31.628c2.94 0 5.326 2.389 5.326 5.34v19.023c0 2.952-2.387 5.341-5.326 5.341H39.497c-2.94 0-5.326-2.389-5.326-5.341V79.656c0-2.952 2.386-5.341 5.325-5.341Z"
          stroke="#FE8568"
          strokeOpacity={0.4}
          strokeWidth={2}
        />
      </g>
      <path
        d="M43.846 74.107h.79v30.119h-.79v-9.511h-9.884v-.793h9.884v-9.51h-9.884v-.794h9.884v-9.51ZM66.38 93.922h10.28v.793H66.38v9.511h-.79V88.374a4.75 4.75 0 0 1 4.744-4.755h6.326v.792h-6.326a3.958 3.958 0 0 0-3.954 3.963v5.548ZM66.38 74.107h-.79v6.341h.79v-6.34Z"
        fill="#FE8568"
        fillOpacity={0.4}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.846 89.167c0 3.064-2.478 5.548-5.535 5.548s-5.535-2.484-5.535-5.548 2.478-5.549 5.535-5.549 5.535 2.485 5.535 5.549Zm-.791 0a4.75 4.75 0 0 1-4.744 4.755 4.75 4.75 0 0 1-4.744-4.755 4.75 4.75 0 0 1 4.744-4.756 4.75 4.75 0 0 1 4.744 4.756Z"
        fill="#FE8568"
        fillOpacity={0.4}
      />
      <g filter="url(#illustration-carte-bancaire_svg__c)">
        <ellipse
          cx={281.977}
          cy={174.075}
          rx={14.752}
          ry={14.787}
          fill="#FE8568"
          fillOpacity={0.24}
        />
      </g>
      <g filter="url(#illustration-carte-bancaire_svg__d)">
        <path
          d="M315.022 174.075c0 8.166-6.604 14.787-14.752 14.787-8.147 0-14.751-6.621-14.751-14.787 0-8.167 6.604-14.788 14.751-14.788 8.148 0 14.752 6.621 14.752 14.788Z"
          fill="#FE8568"
          fillOpacity={0.8}
        />
      </g>
      <g filter="url(#illustration-carte-bancaire_svg__e)">
        <path
          d="M291.124 162.472a14.775 14.775 0 0 0-5.605 11.603c0 4.703 2.191 8.894 5.605 11.602a14.772 14.772 0 0 0 5.606-11.602c0-4.704-2.191-8.895-5.606-11.603Z"
          fill="#FE8568"
        />
      </g>
    </g>
    <rect
      x={2.5}
      y={2.5}
      width={335}
      height={209}
      rx={13.5}
      stroke="#FE8568"
      strokeOpacity={0.24}
      strokeWidth={5}
    />
    <defs>
      <filter
        id="illustration-carte-bancaire_svg__b"
        x={8.171}
        y={48.315}
        width={94.279}
        height={81.704}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={12.5} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_920_1484"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_920_1484"
          result="shape"
        />
      </filter>
      <filter
        id="illustration-carte-bancaire_svg__c"
        x={217.226}
        y={109.287}
        width={129.504}
        height={129.574}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={25} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_920_1484"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_920_1484"
          result="shape"
        />
      </filter>
      <filter
        id="illustration-carte-bancaire_svg__d"
        x={235.519}
        y={109.287}
        width={129.504}
        height={129.574}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={25} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_920_1484"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_920_1484"
          result="shape"
        />
      </filter>
      <filter
        id="illustration-carte-bancaire_svg__e"
        x={235.519}
        y={112.472}
        width={111.211}
        height={123.206}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={25} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_920_1484"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_920_1484"
          result="shape"
        />
      </filter>
      <clipPath id="illustration-carte-bancaire_svg__a">
        <rect width={340} height={214} rx={16} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgIllustrationCarteBancaire
