import me from './me'
import update from './udpate'
import profilePicture from './profilePicture'
import deleteAccount from './deleteAccount'

export const user = {
  me,
  update,
  profilePicture,
  deleteAccount,
}
