import checkPhone from './checkPhone'
import initiateVerification from './initiateVerification'
import getUserToken from './getUserToken'
import register from './register'

export const auth = {
  checkPhone,
  initiateVerification,
  getUserToken,
  register,
}
