import * as React from 'react'

const SvgFaCalendar = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.192 2.25h-6.5V1a.75.75 0 1 0-1.5 0v1.25h-2.25A2.75 2.75 0 0 0 .192 5v12a2.75 2.75 0 0 0 2.75 2.75h14a2.75 2.75 0 0 0 2.75-2.75V5a2.75 2.75 0 0 0-2.75-2.75h-2.25V1a.75.75 0 0 0-1.5 0v1.25Zm-7.25 3.5a.75.75 0 0 1-.75-.75V3.75h-2.25c-.69 0-1.25.56-1.25 1.25v2.5h16.5V5c0-.69-.56-1.25-1.25-1.25h-2.25V5a.75.75 0 0 1-1.5 0V3.75h-6.5V5a.75.75 0 0 1-.75.75ZM1.692 9v8c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25V9h-16.5Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaCalendar
