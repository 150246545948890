export const required = (value) => (value ? undefined : 'Champs obligatoire')

export const mustBeNumber = (value) =>
  value && Number.isNaN(Number(value)) ? 'Doit être un nombre' : undefined

export const minValue = (min) => (value) =>
  Number.isNaN(Number(value)) && value.length > min
    ? `Le nombre de caractères doit être supérieur à ${min}`
    : undefined

export const maxValue = (max) => (value) =>
  value && value.length <= max
    ? undefined
    : `Le nombre de caractères doit être inférieur à ${max}`

export const maxNumber = (max) => (value) =>
  value && value >= 91 ? `La durée ne doit pas éxcéder ${max} jours` : undefined

export const email = (value) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  return value && regex.test(value)
    ? undefined
    : "Le format de l'adresse mail est incorrect"
}

export const phone = (value) => {
  const regex = /^[0-9]{10}$/i
  return value && regex.test(value)
    ? undefined
    : 'Le format du numéro de téléphone est incorrect'
}

export const userName = (value) => {
  const regex = /^[^@]{4,16}$/
  return value && regex.test(value)
    ? undefined
    : "Le nom d'utilisateur doit faire entre 4 et 16 caractères"
}

export const postalCode = (value) =>
  value && !/^[0-9]{5}$/i.test(value)
    ? 'Le code postal doit contenir 5 chiffres'
    : undefined

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    )

export const mustBeDifferentFromInitial = (initialValue) => (value) =>
  value !== initialValue ? null : 'Doit être différent'
