import { selectors } from '../../utils'

const { mergeLoadingSelector } = selectors

export const updateLoaderSelector = (state) => state.user.loading.update
export const meLoaderSelector = (state) => state.user.loading.me
export const profilePictureLoaderSelector = (state) =>
  state.user.loading.profilePicture
export const deleteAccountLoaderSelector = (state) =>
  state.user.loading.deleteAccount

export const updateUserLoaderSelector = mergeLoadingSelector([
  updateLoaderSelector,
  meLoaderSelector,
  profilePictureLoaderSelector,
  deleteAccountLoaderSelector,
])
