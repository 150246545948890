import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import { selectors } from '../../store/selectors'
import {
  FaCross,
  FaPaperPlaneRoundedBlue,
  FaTrashRed,
} from '../../components/Svg'
import Avatar from '../../components/Images/Avatar'
import Loader from '../../components/Loader'
import { InputPhone, InputText } from '../../components/form'
import { composeValidators, required } from '../../helpers/fieldValidator'
import {
  formatPhoneNumberJson,
  normalizePhone,
} from '../../helpers/formatPhoneNumber'
import { actions } from '../../store'
import addContactIllustration from '../../components/Svg/ressources/add-contact-illustration.svg'
import invitedContactIllustration from '../../components/Svg/ressources/invited-contact-illustration.svg'

const ContactWrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 80px 40px;
  .rounded__image {
    &:hover {
      img {
        scale: 1.3;
        transition: scale 0.5s ease;
      }
    }
  }
  .no__friends {
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
    width: 100%;
    margin-right: 130px;
  }
`

const Index = ({ callback }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()
  const contacts = useSelector(selectors.contact.contactsSelector)
  const sendInvitationLoader = useSelector(
    selectors.invitation.sendLoadingSelector
  )
  const contactsByStatus = useSelector(selectors.contact.getAllByStatusSelector)
  const sendedInvitation = useSelector(selectors.invitation.invitationSelector)
  const deletedInvitation = useSelector(
    selectors.invitation.deleteInvitationSelector
  )

  useEffect(() => {
    if (sendedInvitation) {
      dispatch(actions.contact.getAllByStatus.process())
      dispatch(actions.invitation.reset({ key: ['invitation'] }))
      onClose()
    }
  }, [sendedInvitation])

  useEffect(() => {
    if (deletedInvitation) {
      dispatch(actions.contact.getAllByStatus.process())
      dispatch(actions.invitation.reset({ key: ['deleteInvitation'] }))
    }
  }, [deletedInvitation])

  const deleteInvitation = (phoneNumber) =>
    dispatch(
      actions.invitation.deleteInvitation.process(
        encodeURIComponent(phoneNumber)
      )
    )

  const resendInvitation = (data) =>
    dispatch(
      actions.invitation.send.process({
        ...data,
      })
    )

  useEffect(() => {
    dispatch(actions.contact.getAllByStatus.process())
  }, [])

  const sendInvitation = (data) =>
    dispatch(
      actions.invitation.send.process({
        ...data,
        phoneNumber: formatPhoneNumberJson(data.phoneNumber),
      })
    )

  const getCurrentContact = (currentContact) => {
    dispatch(actions.contact.currentContact.process(currentContact))
  }

  return (
    <Box>
      <Modal
        size="sm"
        isOpen={isOpen}
        onClose={() => {
          dispatch(actions.invitation.reset({ keys: ['errors'] }))
          onClose()
        }}
        isCentered
      >
        <ModalOverlay bg="rgba(54, 53, 55, 0.4);" />
        <ModalContent borderRadius={16} p={3}>
          <ModalHeader />
          <ModalCloseButton
            borderRadius="full"
            boxShadow="2px 4px 0 rgba(0, 0, 0, 0.08)"
            p={3}
            color="rgba(54, 53, 55, 0.4)"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <Box>
              <FaCross />
            </Box>
          </ModalCloseButton>
          <Form
            onSubmit={sendInvitation}
            render={({ handleSubmit, pristine }) => (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <Stack spacing={4}>
                    {sendInvitationLoader ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Loader w={200} h={200} />
                      </Box>
                    ) : (
                      <>
                        <Box
                          as="h3"
                          textStyle="h3"
                          textAlign="center"
                          color="primary.400"
                        >
                          Ajoutez un proche
                        </Box>

                        <Box
                          as="p"
                          textStyle="p"
                          textAlign="center"
                          style={{ marginBottom: '22px' }}
                        >
                          Qui souhaitez-vous ajouter ?
                        </Box>
                        <Stack spacing={4}>
                          <Field
                            name="firstName"
                            component={InputText}
                            validate={composeValidators(required)}
                            label="Prénom"
                          />
                          <Field
                            name="phoneNumber"
                            component={InputPhone}
                            validate={composeValidators(required)}
                            parse={normalizePhone}
                            placeholder="XX XX XX XX XX"
                          />
                        </Stack>
                      </>
                    )}
                  </Stack>
                </ModalBody>

                <ModalFooter justifyContent="center">
                  <Button
                    type="submit"
                    w="100%"
                    isDisabled={pristine}
                    style={{ marginTop: '22px' }}
                  >
                    Ajouter
                  </Button>
                </ModalFooter>
              </form>
            )}
          />
        </ModalContent>
      </Modal>

      <ContactWrapper>
        {!contacts ? (
          <Box display="flex" flexDir="column" flex={1}>
            <Box className="no__friends" mb={6}>
              <Box as="h1" textStyle="h1" mb={3}>
                Ajoutez vos proches dans vos contacts index
              </Box>
              <Box as="h3" textStyle="h3">
                afin de leur partager des photos / vidéos
              </Box>
            </Box>
            <Box
              display="flex"
              flexDir="column"
              alignItems="center"
              mr={6}
              onClick={onOpen}
              cursor="pointer"
              className="rounded__image"
            >
              <Image
                src={addContactIllustration}
                alt="add contact"
                h={90}
                borderRadius="full"
                mb={5}
                boxShadow="2px 4px 0 rgba(0, 0, 0, 0.08)"
              />
              <Box color="white" fontWeight={600}>
                Inviter un proche
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              flexDir="column"
              alignItems="center"
              mr={6}
              onClick={onOpen}
              cursor="pointer"
              className="rounded__image"
            >
              <Image
                src={addContactIllustration}
                alt="add contact"
                h={90}
                borderRadius="full"
                mb={5}
                boxShadow="2px 4px 0 rgba(0, 0, 0, 0.08)"
              />
              <Box color="white" fontWeight={600}>
                Inviter un proche
              </Box>
            </Box>
            {contactsByStatus?.invited.map((contact) => (
              <Menu key={contact.user.uuid}>
                <MenuButton className="rounded__image">
                  <Box
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                    mr={6}
                    key={contact.user.uuid}
                  >
                    <Box mb={5}>
                      <Image
                        src={invitedContactIllustration}
                        alt="add contact"
                        h={90}
                        borderRadius="full"
                        boxShadow="2px 4px 0 rgba(0, 0, 0, 0.08)"
                      />
                    </Box>
                    <Box color="white" fontWeight={600}>
                      {contact.user.firstName}
                    </Box>
                  </Box>
                </MenuButton>

                <MenuList>
                  <Box
                    as="h3"
                    textStyle="h3"
                    color="primary.400"
                    p={3}
                    textAlign="center"
                  >
                    {contact.user.firstName}
                  </Box>
                  <MenuDivider />
                  <MenuItem
                    minH="48px"
                    p={3}
                    fontWeight={500}
                    onClick={() =>
                      resendInvitation({
                        firstName: contact.user.firstName,
                        phoneNumber: contact.user.phoneNumber,
                      })
                    }
                  >
                    <FaPaperPlaneRoundedBlue />
                    Renvoyer la demande
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem
                    minH="48px"
                    p={3}
                    color="red.400"
                    fontWeight={500}
                    _hover={{
                      color: 'red.300',
                      svg: {
                        color: 'white',
                        circle: { fillOpacity: '1' },
                      },
                    }}
                    onClick={() => deleteInvitation(contact.user.phoneNumber)}
                  >
                    <FaTrashRed />
                    Supprimer la demande
                  </MenuItem>
                </MenuList>
              </Menu>
            ))}
            {contactsByStatus?.validated.map((contact) => (
              <Link
                key={contact.user.uuid}
                to={`/contacts/${contact.user.firstName}/call`}
                onClick={() => {
                  getCurrentContact(contact.user)
                  callback()
                }}
              >
                <Box
                  display="flex"
                  flexDir="column"
                  alignItems="center"
                  mr={6}
                  key={contact.user.uuid}
                >
                  <Box mb={5} className="rounded__image">
                    <Avatar
                      src={
                        contact.user?.profilePicture?.smallSizeUrl
                          ? contact.user.profilePicture.smallSizeUrl
                          : null
                      }
                      boxShadow="2px 4px 0 rgba(0, 0, 0, 0.08)"
                      boxSize={90}
                      firstName={contact.user.firstName}
                    />
                  </Box>
                  <Box color="white" fontWeight={600}>
                    {contact.user.firstName}
                  </Box>
                </Box>
              </Link>
            ))}
          </>
        )}
      </ContactWrapper>
    </Box>
  )
}

export default Index
