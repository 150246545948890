import { selectors } from '../../utils'

const { mergeLoadingSelector } = selectors

export const sendLoadingSelector = (state) => state.invitation.loading.send
export const acceptLoadingSelector = (state) => state.invitation.loading.accept
export const deleteInvitationLoadingSelector = (state) =>
  state.invitation.loading.deleteInvitation

export const loadingSelector = mergeLoadingSelector([sendLoadingSelector])
