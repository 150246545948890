import { paths } from './paths'
import PrivateRoute from '../PrivateRoute'
import Contacts from '../../views/Contacts'
import Contact from '../../views/Contacts/Contact'
import Call from '../../views/Contacts/Contact/Call'
import TakeMedia from '../../views/Contacts/Contact/TakeMedia'
import SendMedia from '../../views/Contacts/Contact/SendMedia'
import SendAudio from '../../views/Contacts/Contact/SendAudio'

const routes = [
  {
    path: paths.CONTACTS,
    element: <PrivateRoute />,
    children: [
      {
        path: paths.CONTACTS,
        element: <Contacts />,
      },
      {
        path: paths.CONTACT,
        element: <Contact />,
        children: [
          {
            path: paths.CALL,
            element: <Call />,
          },
          {
            path: paths.TAKE_MEDIA,
            element: <TakeMedia />,
          },
          {
            path: paths.SEND_MEDIA,
            element: <SendMedia />,
          },
          {
            path: paths.SEND_AUDIO,
            element: <SendAudio />,
          },
        ],
      },
    ],
  },
]

export default routes
