import * as React from 'react'

const SvgFaPaperPlaneRoundedBlue = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <circle cx={25} cy={25} r={25} fill="#6891FB" fillOpacity={0.16} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.157 16.22a.748.748 0 0 1 .162.819l-7.547 18.866a.75.75 0 0 1-1.385.017l-3.28-7.652-7.652-3.28a.75.75 0 0 1 .017-1.386l18.866-7.546a.759.759 0 0 1 .36-.055.75.75 0 0 1 .46.217Zm-3.644 2.584-13.803 5.52 5.797 2.485 8.006-8.006Zm-6.945 9.066 2.484 5.797 5.521-13.803-8.005 8.006Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaPaperPlaneRoundedBlue
