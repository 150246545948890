import React, { useState } from 'react'
import { Checkbox, Box, Image, Button, Stack, Link } from '@chakra-ui/react'
import CircleAvatar from '../../../../styles/onBoarding/illustrations/CircleAvatar'
import { FormCard } from '../../../../components/cards'
import { BackButton } from '../../../../components'
import { legalsLink } from '../../../../helpers/legalsLinks'

const Index = ({ inviter, next, prev }) => {
  const [enabledCreateAccount, setEnabledCreateAccount] = useState(false)
  return (
    <>
      <BackButton callback={() => prev()} />
      <FormCard>
        <Image
          style={{
            zIndex: 3,
            top: -64.5,
            left: '50%',
            marginLeft: -64.5,
            position: 'absolute',
          }}
          border="8px solid white"
          borderRadius="full"
          boxSize="129px"
          bg="white"
          src={inviter.profilePictureUrl.thumbnailSizeUrl}
          alt={`image de ${inviter.firstname}`}
        />

        <CircleAvatar
          style={{
            width: 234,
            zIndex: 1,
            top: 0,
            left: '50%',
            marginLeft: -116,
            position: 'absolute',
          }}
        />
        <Stack spacing={8} mt={20}>
          <Box as="h2" textStyle="h2">
            {inviter.firstname} vous a invité(e) <br />
            sur Index
          </Box>

          <Box display="flex">
            <Checkbox
              size="lg"
              colorScheme="white"
              iconColor="primary.400"
              onChange={() => setEnabledCreateAccount(!enabledCreateAccount)}
            />
            {/* <Box as="p" textStyle="p" ml={3}> */}
            {/*  Je certifie avoir lu et accepté les{' '} */}
            {/*  <Link className="link" href={legalsLink.CGV} target="_blank"> */}
            {/*    CGV */}
            {/*  </Link>{' '} */}
            {/*  et les{' '} */}
            {/*  <Link className="link" href={legalsLink.CGU} target="_blank"> */}
            {/*    CGU */}
            {/*  </Link> */}
            {/* </Box> */}
            <Box as="p" textStyle="p" ml={3}>
              Je certifie avoir lu et accepté les{' '}
              <Link className="link" href={legalsLink.CGU} target="_blank">
                CGU
              </Link>
            </Box>
          </Box>
          <Button disabled={!enabledCreateAccount} onClick={() => next()}>
            Créer mon compte
          </Button>
        </Stack>
      </FormCard>
    </>
  )
}

export default Index
