import * as React from 'react'

const SvgFaMic = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <circle cx={25} cy={25} r={25} fill="#6891FB" fillOpacity={0.16} />
    <path
      d="M25.35 34.429v.857h.85c.304 0 .584.163.736.428a.863.863 0 0 1 0 .857.849.849 0 0 1-.736.429h-3.4a.849.849 0 0 1-.736-.429.863.863 0 0 1 0-.857.849.849 0 0 1 .736-.428h.85v-.857a8.475 8.475 0 0 1-5.468-2.795A8.612 8.612 0 0 1 16 25.857c0-.306.162-.59.425-.742a.844.844 0 0 1 .85 0 .859.859 0 0 1 .425.742 6.867 6.867 0 0 0 3.4 5.938 6.75 6.75 0 0 0 6.8 0 6.868 6.868 0 0 0 3.4-5.938c0-.306.162-.59.425-.742a.844.844 0 0 1 .85 0 .859.859 0 0 1 .425.742 8.613 8.613 0 0 1-2.182 5.777 8.475 8.475 0 0 1-5.468 2.795Zm-5.95-8.572v-7.714c0-1.838.972-3.535 2.55-4.454a5.064 5.064 0 0 1 5.1 0 5.151 5.151 0 0 1 2.55 4.454v7.714a5.151 5.151 0 0 1-2.55 4.454 5.064 5.064 0 0 1-5.1 0 5.151 5.151 0 0 1-2.55-4.454Zm1.7 0c0 1.225.648 2.357 1.7 2.97a3.375 3.375 0 0 0 3.4 0 3.434 3.434 0 0 0 1.7-2.97v-7.714a3.434 3.434 0 0 0-1.7-2.97 3.376 3.376 0 0 0-3.4 0 3.434 3.434 0 0 0-1.7 2.97v7.714Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaMic
