import { request } from '../../http'

export default () =>
  async ({ data, headers }) =>
    request({
      route:
        data && data?.lat && data?.long
          ? `/weather?lat=${data.lat}&lon=${data.long}`
          : `/weather`,
      method: 'GET',
      headers,
    })
