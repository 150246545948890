import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Sidebar from './Sidebar'
import { paths } from '../../../router/paths'
import { StyledLeftWrapper } from '../../../styles/Contact/Sidebar'
import { StyledContactWrapper } from '../../../styles/Contact'
import { StyledRightWrapper } from '../../../styles/Contact/RightWrapper'
import { selectors } from '../../../store/selectors'
import { actions } from '../../../store'

const Index = () => {
  const dispatch = useDispatch()

  const currentContact = useSelector(selectors.contact.currentContactSelector)

  useEffect(
    () => () => dispatch(actions.contact.reset({ keys: ['currentContact'] })),
    []
  )

  if (!currentContact) return <Navigate replace to={paths.ON_BOARDING} />

  return (
    <StyledContactWrapper>
      <StyledLeftWrapper>
        <Sidebar user={currentContact} />
      </StyledLeftWrapper>

      <StyledRightWrapper>
        <Outlet context={currentContact} />
      </StyledRightWrapper>
    </StyledContactWrapper>
  )
}

export default Index
