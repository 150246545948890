import api from '../../services/api'
import { sagas } from '../../utils'
import { accept as actions } from '../actions'

export default () =>
  sagas.createDefaultSaga({
    apiCall: api.invitation.accept,
    actions,
    payloadKey: 'accept',
  })
