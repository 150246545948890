import * as React from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react'

export const InputSelect = ({
  files,
  input,
  meta,
  onChange,
  invalid,
  isFacultatif,
  maxLength,
  options,
  withoutDefault = true,
  withoutSearch = true,
  onSearchChange,
  labelValueNull = 'choisir',
  ...rest
}) => (
  <FormControl>
    {rest.label && (
      <FormLabel>
        {rest.label}{' '}
        <span style={{ fontStyle: 'italic' }}>
          {isFacultatif && '(facultatif)'}
        </span>
      </FormLabel>
    )}
    <Stack spacing={3}>
      {!withoutSearch && (
        <Input
          name="Recherche"
          onChange={(value) => onSearchChange(value.target.value)}
          placeholder="Recherche"
        />
      )}
      <Select
        {...input}
        isInvalid={(meta.error || meta.submitError) && meta.touched}
        borderColor="#F4F0E3"
        height="56px"
      >
        <option value={null} style={{ fontWeight: 800 }}>
          {labelValueNull}
        </option>
        {!withoutDefault && <option value="aucun">Aucun</option>}
        {options.map((e) => (
          <option key={e.name} value={e.value}>
            {e.name}
          </option>
        ))}
      </Select>
    </Stack>
    {(meta.error || meta.submitError) && meta.touched && (
      <Box as="p" fontWeight="700" color="red.400" textAlign="left">
        {meta.error || meta.submitError}
      </Box>
    )}
  </FormControl>
)
