import * as React from 'react'

const SvgFaChecked = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.982 11.664c.495.482 1.305.44 1.745-.092l8.008-9.674a1.151 1.151 0 0 0-.17-1.637 1.194 1.194 0 0 0-1.661.167L4.73 9.12 2.011 6.475a1.193 1.193 0 0 0-1.67.01 1.15 1.15 0 0 0 .01 1.644l3.63 3.536"
      fill="currentColor"
    />
  </svg>
)
export default SvgFaChecked
