import { select } from 'redux-saga/effects'

function* createDefaultHeaders(isAuthenticated) {
  let headers = {}
  if (isAuthenticated) {
    const { token } = yield select((state) => state.session || {})
    headers = {
      Authorization: `Bearer ${token}`,
    }
  }
  return headers
}

export default createDefaultHeaders
